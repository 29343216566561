<template>
  <div v-if="isPermission">
    <form>
      <div v-if="showDetail">
        <h5>{{ titlename }}</h5>
        <br />
        <CRow>
          <CCol md="3" sm="4">
            <div v-if="imageData.length > 0">
              <img :src="imageData" :ref="imageData" @click="selectcolormodal" alt="..." width="100%"
                class="card-img avatar" @error="noImgUrl" />
            </div>
            <div v-else>

              <CButton :style="{ backgroundColor: colori }" @click="selectcolormodal"
                style="width: 100%; height: 136px">
                <CIcon>
                  <i class="fas fa-square"></i>
                </CIcon>
              </CButton>
            </div>
          </CCol>
          <CCol md="9" sm="8" class="form-group">
            <CRow>
              <CCol md="12">
                <span>{{ $t("productName") }}</span>
                <CInput :disabled="isEcommercePage" v-model="SKUName" :placeholder="$t('productName')"
                  valid-feedback="input name!" />
              </CCol>
              <CCol md="6" sm="6">
                <span>{{ $t("price") }}</span>
                <CInput v-model.number="SKUPrice" min="0" type="number" :placeholder="$t('price')"
                  valid-feedback="กรอกราคาสินค้า." />
              </CCol>
              <CCol md="6" sm="6">
                <!-- <span>{{ $t('price') }}</span> -->
                <span>{{ $t("deliveryPrice")}}</span>
                <CButton block color="primary" @click="addDeliveryPrice(itemDeleivery)" v-if="isSaveNew === true">
                  {{ $t("moredeli") }}
                </CButton>
                <CButton block color="primary" @click="addNewDeliveryPrice"  v-if="isAddNewMorePrice=== true">
                  {{ $t("moredeli") }} 
                </CButton>
              </CCol>
              <CCol md="4" sm="6">
                <span>{{ $t("pluCode") }}</span>
                <CInput v-model="productcode" :disabled="isEcommercePage" :placeholder="$t('pluCode')" />
              </CCol>
              <CCol md="4" sm="6" class="">
                <span>{{ $t("unit") }}</span>
                <select :disabled="isEcommercePage" class="custom-select" v-model="unit">
                  <option value selected>{{ $t("unit") }}</option>
                  <option v-for="unit in unitlist" :key="unit.objectId" :value="unit">
                    {{ unit.name }}
                  </option>
                </select>
              </CCol>
              <CCol md="4" sm="6" class="">
                <span>{{ $t("SKURatio") }}</span>
                <CInput v-if="indexdata == 0" v-model.number="SKURatio" :placeholder="$t('SKURatio')" min="0" disabled
                  :disabled="isEcommercePage" />
                <CInput v-else v-model.number="SKURatio" type="number" :placeholder="$t('SKURatio')" min="1"
                  :disabled="isEcommercePage" />
              </CCol>
              <CCol md="6" sm="6" v-show="isEcommercePage">
                <span>{{ $t("inventoryQuantity") }}</span>
                <CInput v-model.number="inventoryQuantity" min="0" type="number"
                  :placeholder="$t('inventoryQuantity')" />
              </CCol>
              <CCol md="6" sm="6" class="custom-control custom-checkbox mt-3">
                <input type="checkbox" class="custom-control-input success" v-model="mixedPrice" id="mixedPrice" />
                <label class="custom-control-label" for="mixedPrice">
                  {{ $t("useStepPrice") }}
                </label>
              </CCol>
              <CCol sm="6" col="6" class="form-group mt-3" v-if="isMenuEnabled">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input success cursor" id="isMenu" v-model="isMenu" />
                  <label class="custom-control-label cursor" for="isMenu">
                    {{ $t("isMenu") }}</label>
                </div>
              </CCol>
            </CRow>

          </CCol>
        </CRow>
        <CRow class="justify-content-between col-md-12">
          <CCol col="6" lg="6">
            <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="valtdateaddPLU">
              {{ $t("save") }}
            </CButton>
            <CButton block color="success" v-else-if="loadingSaveimg === false" disabled>
              <CSpinner color="white" size="sm" /> {{ $t("save") }}
            </CButton>
          </CCol>
          <CCol col="6" lg="6">
            <CButton block color="light" @click="cancelUpdate()">
              {{ $t("cancel") }}
            </CButton>
          </CCol>
        </CRow>
        <CModal :show.sync="addDeliverlyPriceModal" size="xl">
          <ul class="list-group">
            <li class="list-group-item" 
              v-for="(item, index) in salesChannel" :key="index"
              :class="[
                { 'table-light': item.enabled === true }, 
                item.id ==='2' ? 'hide-display' : ''
              ]"
            >
              <div class="row">
                <div class="col-3 row">
                  <div class="col-4">
                    <img :src="item.img" class="img-fluid rounded-circle" width="60px" />
                  </div>
                  <div class="col">
                    <h6 class="mb-0">{{ $t(item.name) }}</h6>
                    <p class="mb-0">
                      <CBadge color="info" v-if="item.gpPercent === 'NO GP'">
                        {{ item.gpPercent }}
                      </CBadge>
                      <CBadge color="success" v-else>
                        GP : {{ item.gpPercent }}
                      </CBadge>
                    </p>
                  </div>
                </div>
                <div class="col-lg-12 col-12 text-left p-2" v-if="item.id == '101'">
                <div >
                  <CCallout color="primary" class="">
                    <CAlert class="mr-4 mt-3 mb-3" color="secondary">
                      <CCol md="12" class="form-group row mt-1 mb-1">
                        <label id="productInformationCategoryLabel"
                          class="col-sm-3 col-form-label text-left text-dark w-100 w-sm-auto">
                          {{ $t('salesHours') }}
                        </label>
                        <div id="productInformationCategoryInputContainer" class="col-sm-9">
                          <Multiselect id="productPriceSellingTimeSelect" 
                            v-model="sellingTime"    :disabled="!item.isEdit"
                            :options="sellingTimelist" class="cursor "
                            :placeholder="$t('All Days')" label="name" track-by="id"></Multiselect>
                            <CAlert
                              id="addSalesHoursSettingInfoAlert"
                              color="info"
                              class="d-inline-block col-12 mt-2"
                              style="font-size: 14px; padding: 8px 12px"
                            >
                              <i class="fi fi-rr-info mr-1"></i>
                              {{ $t("setSellingHoursForProduct") }}
                            </CAlert>
                        </div>
                      </CCol>
                    </CAlert>
                    <div class="row">
                      <div class="col-lg-12 col-12 row">
                        <div class="col-lg-1 col-5">
                          <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.deliveryOnDemand"  :disabled="!item.isEdit"
                            @update:checked="updateProductPriceByChannel(item, index)" />
                        </div>
                        <div class="col-lg-2 col-7 ">
                          <p class="mt-2 ml-3 text-left">{{ $t('deliveryOnDemand') }}</p>
                        </div>

                        <div class="col-lg-5 col-12 text-left row mr-3">
                          <label class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                             {{ $t('difference') }}
                          </label>
                          <div class="col-sm-8 col-lg-8 col-8 ">
                            <input class="form-control text-danger"
                              :value="getDifferent(item.advancedPricing.deliveryOnDemand)"
                              v-if="Number(getDifferent(item.advancedPricing.deliveryOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryOnDemand)))"
                              disabled />
                            <input class="form-control  " :value="getDifferent(item.advancedPricing.deliveryOnDemand)"
                              disabled
                              v-else-if="Number(getDifferent(item.advancedPricing.deliveryOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryOnDemand))) === 0" />
                            <input class="form-control text-success"
                              :value="getDifferent(item.advancedPricing.deliveryOnDemand)" disabled v-else />
                          </div>
                        </div>

                        <div class="col-lg-4 col-12 text-left row ">
                          <label class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('pricePackage') }}
                          </label>
                          <div class="col-sm-9 col-lg-9 col-8" >
                            <CInput v-model.number="item.advancedPricing.deliveryOnDemand" placeholder="Price"
                              type="number" :disabled="item.isEdit === false" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 row">
                        <div class="col-lg-1 col-5">
                          <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.deliveryScheduled"
                            @update:checked=" updateProductPriceByChannel(item, index) "  :disabled="!item.isEdit" />
                        </div>
                        <div class="col-lg-2 col-7">
                          <p class="mt-2 ml-3 text-left">{{ $t('deliveryScheduled') }}</p>
                        </div>

                        <div class="col-lg-5 col-12 text-left row mr-3">
                          <label class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('difference') }}
                          </label>
                          <div class="col-sm-8 col-lg-8 col-8">
                            <input class="form-control text-danger"
                              :value="getDifferent(item.advancedPricing.deliveryScheduled)"
                              v-if="Number(getDifferent(item.advancedPricing.deliveryScheduled) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryScheduled)))"
                              disabled />
                            <input class="form-control  " :value="getDifferent(item.advancedPricing.deliveryScheduled)"
                              disabled
                              v-else-if="Number(getDifferent(item.advancedPricing.deliveryScheduled) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryScheduledGrabApp))) === 0" />
                            <input class="form-control text-success"
                              :value="getDifferent(item.advancedPricing.deliveryScheduled)" disabled v-else />

                          </div>
                        </div>

                        <div class="col-lg-4 col text-left row">
                          <label class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('pricePackage') }}
                          </label>
                          <div class="col-sm-9 col-lg-9 col-8" >
                            <CInput v-model.number="item.advancedPricing.deliveryScheduled" placeholder="Price"
                              type="number" :disabled="item.isEdit === false" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 row">
                        <div class="col-lg-1 col-5">
                          <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.selfPickUpOnDemand"  :disabled="!item.isEdit"
                            @update:checked="
                              updateProductPriceByChannel(item, index)
                              " />
                        </div>
                        <div class="col-lg-2 col-7">
                          <p class="mt-2 ml-3 text-left">{{ $t('selfPickUpOnDemand') }}</p>
                        </div>

                        <div class="col-lg-5 col-12 text-left row mr-3">
                          <label class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('difference') }}
                          </label>
                          <div class="col-sm-8 col-lg-8 col-8">
                            <input class="form-control text-danger"
                              :value="getDifferent(item.advancedPricing.selfPickUpOnDemand)"
                              v-if="Number(getDifferent(item.advancedPricing.selfPickUpOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.selfPickUpOnDemand)))"
                              disabled />
                            <input class="form-control  " :value="getDifferent(item.advancedPricing.selfPickUpOnDemand)"
                              disabled
                              v-else-if="Number(getDifferent(item.advancedPricing.selfPickUpOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.SelfPickUp_OnDemand_GrabApp))) === 0" />
                            <input class="form-control text-success"
                              :value="getDifferent(item.advancedPricing.selfPickUpOnDemand)" disabled v-else />

                          </div>
                        </div>

                        <div class="col-lg-4 col col-12 text-left row ">
                          <label class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('pricePackage') }}
                          </label>
                          <div class="col-sm-9 col-lg-9 col-8" >
                            <CInput v-model.number="item.advancedPricing.selfPickUpOnDemand" placeholder="Price"
                              type="number" :disabled="item.isEdit === false" />
                          </div>
                        </div>
                      </div>

                      <div class="col-12 row">
                        <div class="col-lg-1 col-5">
                          <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.dineInOnDemand"  :disabled="!item.isEdit"
                            @update:checked="updateProductPriceByChannel(item, index)" />
                        </div>
                        <div class="col-lg-2 col-7">
                          <p class="mt-2 ml-3 text-left">{{ $t('dineInOnDemand') }}</p>
                        </div>

                        <div class="col-lg-5 col col-12 text-left row mr-3">
                          <label class="col-sm-4 col-lg-4 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('difference') }}
                          </label>
                          <div class="col-sm-8 col-lg-8 col-8">
                            <input class="form-control text-danger"
                              :value="getDifferent(item.advancedPricing.dineInOnDemand)"
                              v-if="Number(getDifferent(item.advancedPricing.dineInOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.dineInOnDemand)))"
                              disabled />
                            <input class="form-control  " :value="getDifferent(item.advancedPricing.dineInOnDemand)"
                              disabled
                              v-else-if="Number(getDifferent(item.advancedPricing.dineInOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.dineInOnDemand))) === 0" />
                            <input class="form-control text-success"
                              :value="getDifferent(item.advancedPricing.dineInOnDemand)" disabled v-else />

                          </div>
                        </div>

                        <div class="col-lg-4 col text-left col-12 row ">
                          <label class="col-sm-3 col-lg-3 col-4  col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('pricePackage') }}
                          </label>
                          <div class="col-sm-9 col-lg-9 col-8" >
                            <CInput v-model.number="item.advancedPricing.dineInOnDemand" placeholder="Price"
                              type="number" :disabled ="item.isEdit === false"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CCallout>
                  <div class="col" v-if="item.isEdit === false">
                    <button class="btn btn-primary btn-sm btn-block" @click="changeItemEdit(index)"
                      v-c-tooltip="{ content: $t('edit'), placement: 'bottom' }">
                      <i class="fi fi-rr-edit"></i>

                    </button>
                  </div>
                  <div class="col row" v-else>
                    <div style="width: 50%">
                      <button class="btn btn-success btn-sm btn-block ml-1" @click="updateItemEdit(index)"
                        v-c-tooltip="{ content: $t('save'), placement: 'bottom' }">
                        <CIcon name="cil-save" />
                      </button>
                    </div>
                    <div style="width: 50%">
                      <button class="btn btn-dark btn-sm btn-block ml-2" @click="changeItemEdit(index)"
                        v-c-tooltip="{ content: $t('cancel'), placement: 'bottom' }">
                        <CIcon name="cil-x" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                <div class="col-9 text-right" v-else-if="item.id == '1'">
                  <h5 class="text-success">Price : {{ item.price }}</h5>
                </div>
                <div class="col-9 text-right" v-else>
                  <div class="row">
                    <div class="col-9 col-lg-10">
                      <div class="form-group row mb-0">
                        <div class="col-6 row">
                          <label class="col-sm-4 col-lg-5 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('difference') }}
                          </label>
                          <div class="col-sm-8 col-lg-7">
                            <input class="form-control text-danger" :value="getDifferent(item.price)" 
                            v-if="Number(getDifferent(item.price) < 0) || Number.isNaN(parseInt(getDifferent(item.price)) ) " disabled />
                            <input class="form-control" :value="getDifferent(item.price)" disabled v-else-if=" Number(getDifferent(item.price) == 0) ||
                              Number.isNaN( parseInt(getDifferent(item.price))) === 0 " />
                            <input class="form-control text-success" :value="getDifferent(item.price)" disabled
                              v-else />
                          </div>
                        </div>
                        <div class="col-6 row">
                          <label class="col-sm-3 col-lg-3 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('pricePackage') }}
                          </label>
                          <div class="col-sm-9 col-lg-9" v-if="item.isEdit === false">
                            <CInput v-model.number="item.price" placeholder="Price" type="number" disabled />
                          </div>
                          <div v-else class="col-sm-9 col-lg-9">
                            <CInput v-model.number="item.price" placeholder="GP (%)" type="number" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-lg-2" v-if="item.isEdit === false">
                      <button class="btn btn-primary btn-sm btn-block" @click="changeItemEdit(index)" 
                      v-c-tooltip="{content: $t('edit'),placement: 'bottom',}">
                        <i class="fi fi-rr-edit"></i>
                      </button>
                    </div>
                    <div class="col-2 row" v-else>
                      <div style="width: 50%">
                        <button class="btn btn-success btn-sm btn-block ml-1" @click="updateItemEdit(index)"
                          v-c-tooltip="{content: $t('save'),placement: 'bottom',}">
                          <CIcon name="cil-save" />
                        </button>
                      </div>
                      <div style="width: 50%">
                        <button class="btn btn-dark btn-sm btn-block ml-2" 
                          v-c-tooltip="{content: $t('cancel'), placement: 'bottom',}" @click="changeItemEdit(index)">
                          <CIcon name="cil-x" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <template #footer>
            <div></div>
          </template>
        </CModal>
        <hr />

      </div>
      <div class="mt-1" v-else>
        <CRow v-if="items.length > 0">
          <CCol lg="12">
            <!-- <CCard>
              <CCardBody> -->
                <CRow>
                  <!-- <CCol lg="3" md="4" col="6">
                    <CCard class="list cursor" @click="openAddPluModal()">
                      <CCardBody class="product-body">
                        <br />
                        <p style="
                            text-align: end;
                            margin-top: -7px;
                            margin-bottom: 0rem;
                          " class="success">
                        </p>
                        <img src="/img/Add_Png.png" style="border-radius: 5px; aspect-ratio: 4 / 3" class="img-fluid"
                          @error="noImgUrl" />
                        <CButton style="margin-bottom: 0rem; margin-top: 1.35rem" block color="success"
                          @click="openAddPluModal()">
                          Add Package
                        </CButton>
                      </CCardBody>
                    </CCard>
                  </CCol> -->
                  <CCol v-for="(item, index) in items" :key="index" lg="3" md="4" col="6">
                    <CCard class="cursor pkhover ">
                      <CCardBody class="product-body ">
                        <img v-if="item.remoteImagePath" :src="item.remoteImagePath"
                          style="border-radius: 5px; aspect-ratio: 4 / 3" class="img-fluid" @error="noImgUrl" />
                        <div v-else style="border-radius: 5px; aspect-ratio: 4 / 3" :style="{
                          backgroundColor: item.colori,
                        }"></div>
                        <tr>
                          <td style="width: 80%">
                            <p style="margin-bottom: 0rem; margin-top: 1rem" class="limit-2">
                              {{ item.name }}
                              <CBadge color="secondary">{{
                                item.unit.name
                              }}</CBadge>
                            </p>
                          </td>
                          <td style="text-align: end">
                            {{ item.price }}
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: end">
                            <p style="
                                text-align: start;
                                margin-top: 0px;
                                margin-bottom: 0rem;
                              " @click="openEditPluModal(item, index)">
                              <img src="/img/file-edit.png" style="width: 14px; height: 14px" />
                            </p>
                          </td>
                          <td>
                            <p style="
                                text-align: end;
                                margin-top: 0px;
                                margin-bottom: 0rem;
                              " @click="deletePLU(item, index)">
                              <img src="/img/trash.png" style="
                                  width: 14px;
                                  height: 14px;
                                  cursor: pointer;
                                " />
                            </p>
                          </td>
                        </tr>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              <!-- </CCardBody>
            </CCard> -->
          </CCol>
        </CRow>
        <CRow v-else>
          <CCol lg="3" md="3" col="6">
            <CCard class="list cursor pkhover" @click="openAddPluModal()" >
              <CCardBody class="product-body text-center">
                <br />
                <img src="/img/Add_Png.png" style="border-radius: 5px; aspect-ratio: 4 / 3" class="img-fluid text-center"
                  @error="noImgUrl" />
                <CButton style="margin-bottom: 0rem; margin-top: 1.35rem" block color="success"
                  @click="openAddPluModal()">
                  {{ $t('addProductPLU') }}
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <CRow>
        <CModal :show.sync="addPLUModal" size="lg" :title="titlename" centered>
          <CRow>
            <CCol md="3" sm="4">
              <div v-if="imageData.length > 0">
                <img :src="imageData" :ref="imageData" @click="selectcolormodal" alt="..." width="100%"
                  class="card-img avatar" @error="noImgUrl" />
              </div>
              <div v-else>
                <CButton :style="{ backgroundColor: colori }" @click="selectcolormodal"
                  style="width: 100%; height: 136px">
                  <CIcon>
                    <i class="fas fa-square"></i>
                  </CIcon>
                </CButton>
              </div>
            </CCol>
            <CCol md="9" sm="8" class="form-group">
              <CRow>
                <CCol md="12">
                  <span>{{ $t("productName") }}</span>
                  <CInput :disabled="isEcommercePage" v-model="SKUName" :placeholder="$t('productName')"
                    valid-feedback="input name!" />
                </CCol>
                <CCol md="6" sm="6">
                  <span>{{ $t("price") }}</span>
                  <CInput v-model.number="SKUPrice" min="0" type="number" :placeholder="$t('price')"
                    valid-feedback="กรอกราคาสินค้า." />
                </CCol>
                <CCol md="6" sm="6">
                  <span>{{ $t("pluCode") }}</span>
                  <CInput v-model="productcode" :disabled="isEcommercePage" :placeholder="$t('pluCode')" />
                </CCol>
                <CCol md="6" sm="6" class="">
                  <span>{{ $t("unit") }}</span>
                  <select :disabled="isEcommercePage" class="custom-select" v-model="unit">
                    <option value selected>{{ $t("unit") }}</option>
                    <option v-for="unit in unitlist" :key="unit.objectId" :value="unit">
                      {{ unit.name }}
                    </option>
                  </select>
                </CCol>
                <CCol md="6" sm="6" class="">
                  <span>{{ $t("SKURatio") }}</span>
                  <CInput v-if="indexdata == 0" v-model.number="SKURatio" :placeholder="$t('SKURatio')" min="0" disabled
                    :disabled="isEcommercePage" />
                  <CInput v-else v-model.number="SKURatio" type="number" :placeholder="$t('SKURatio')" min="1"
                    :disabled="isEcommercePage" />
                </CCol>
                <CCol md="6" sm="6" v-show="isEcommercePage">
                  <span>{{ $t("inventoryQuantity") }}</span>
                  <CInput v-model.number="inventoryQuantity" min="0" type="number"
                    :placeholder="$t('inventoryQuantity')" />
                </CCol>
                <CCol md="6" sm="6" class="custom-control custom-checkbox mt-3">
                  <input type="checkbox" class="custom-control-input success" v-model="mixedPrice" id="mixedPrice" />
                  <label class="custom-control-label" for="mixedPrice">
                    {{ $t("useStepPrice") }}
                  </label>
                </CCol>
                <CCol sm="6" col="6" class="form-group mt-3" v-if="isMenuEnabled">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input success cursor" id="isMenu" v-model="isMenu" />
                    <label class="custom-control-label cursor" for="isMenu">
                      {{ $t("isMenu") }}</label>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5" lg="4">
                <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="valtdateaddPLU">
                  {{ $t("save") }}
                </CButton>
                <CButton block color="success" v-else-if="loadingSaveimg === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="cancelUpdate()">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal color="success" :show.sync="selectcolor" :title="$t('selectImg')" centered>
          <div v-if="loadingSaveimg">
            <CRow>
              <CCol md="1" sm="1" col="2" style="margin: 3px" v-for="item in colorList" :key="item.value"
                :value="item.value">
                <CButton v-if="indexColor === item.value" style="
                    box-shadow: 0 0 5px 5px rgb(163, 222, 194);
                    color: white;
                  " :class="{ active: indexColor === item.value }" @click="setindexcolor(item.value)"
                  :style="{ backgroundColor: item.color }">
                  <i class="fi fi-rr-check"></i>
                </CButton>
                <CButton v-else :style="{ backgroundColor: item.color }" :class="{ active: indexColor === item.value }"
                  @click="setindexcolor(item.value)">
                  <CIcon>
                    <i class="fas fa-square fa-2x"></i>
                  </CIcon>
                </CButton>
              </CCol>
            </CRow>
            <hr />
            <div class="row">
              <div v-if="imageDataBtn.length > 0" class="col-md-6 col-6">
                <CButton @click="pickFile">
                  <label>{{ $t("selectImg") }}</label>
                  <input type="file" ref="image" style="display: none" accept="image/*" @change="uploadImage" />
                </CButton>
              </div>
              <div v-else class="col-md-6 col-6">
                <CButton @click="pickFile">
                  <label>{{ $t("selectImgFromPC") }}</label>
                  <input type="file" ref="image" style="display: none" accept="image/*" @change="uploadImage" />
                </CButton>
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-6 col-6 text-right">
                <CButton @click="removeImage">{{ $t("deleteImg") }}</CButton>
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-12 text-center">
                <img v-if="isUpload" :src="imageData" alt="..." width="40%" @click="selectcolormodal" class="avatar"
                  @error="noImgUrl" />
                <VueCropper v-else v-show="imageDataBtn" :guides="true" :aspectRatio="4 / 4" :initialAspectRatio="4 / 1"
                  ref="cropper" :src="imageDataBtn" alt="Source Image">
                </VueCropper>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center" width="100%">
              <button class="btn" type="button" disabled style="color: darkcyan">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                </span>
                {{ $t("กำลังอัปโหลดรูปภาพ...") }}
              </button>
            </div>
          </div>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5" lg="4">
                <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="savecolorimg">
                  {{ $t("save") }}
                </CButton>
                <CButton block color="success" v-else-if="loadingSaveimg === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="selectcolor = false">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal color="success" :show.sync="confirmModal" centered>
          <h4 class="text-center">{{ $t("confirmSaveImgDialog") }}</h4>
          <br />
          <h5 class="text-center">{{ $t("confirmSaveImg") }}</h5>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol v-show="!isEcommercePage" col="5" lg="4" v-if="loadingSaveimg === true">
                <CButton block v-if="showbuttonsave" color="success" v-on:click="saveProductPLU">
                  {{ $t("save") }}
                </CButton>
                <CButton block color="success" v-else v-on:click="EditProductPLU">
                  {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol v-show="isEcommercePage" col="5" lg="4" v-if="loadingSaveimg === true">
                <CButton block color="success" v-on:click="saveEcommerceInfo">
                  {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol col="5" lg="4" v-else-if="loadingSaveimg === false">
                <CButton block color="success" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="confirmModal = false">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
        <CRow>
          <modal color="danger" :show.sync="deleteModal" :title="$t('confirmDeleteProduct')" v-on:isSave="deletePlu"
            centered :loading="loading">
            <br />
            <h4 class="text-center">{{ $t("comfrimDeletePLU") }}</h4>
            <br />
          </modal>
        </CRow>
        <CModal :show.sync="errorModel" centered color="danger">
          <div>
            <div class="d-flex flex-column align-items-center">
              <h4 class="mt-3">{{ $t("systemError") }}</h4>
            </div>
          </div>
          <template #footer>
            <div class="text-center w-100">
              <CButton color="danger" class="w-50" @click="errorModel = false">{{ $t("submit") }}</CButton>
            </div>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal color="success" :show.sync="confirmModal" centered>
          <h4 class="text-center">{{ $t("confirmSaveImgDialog") }}</h4>
          <br />
          <h5 class="text-center">{{ $t("confirmSaveImg") }}</h5>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol v-show="!isEcommercePage" col="5" lg="4" v-if="loadingSaveimg === true">
                <CButton block v-if="showbuttonsave" color="success" v-on:click="saveProductPLU">
                  {{ $t("save") }}
                </CButton>
                <CButton block color="success" v-else v-on:click="EditProductPLU">
                  {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol v-show="isEcommercePage" col="5" lg="4" v-if="loadingSaveimg === true">
                <CButton block color="success" v-on:click="saveEcommerceInfo">
                  {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol col="5" lg="4" v-else-if="loadingSaveimg === false">
                <CButton block color="success" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("save") }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="confirmModal = false">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>

        <CModal :show.sync="errorModel" centered color="danger">
          <div>
            <div class="d-flex flex-column align-items-center">
              <h4 class="mt-3">{{ $t("systemError") }}</h4>
            </div>
          </div>
          <template #footer>
            <div class="text-center w-100">
              <CButton color="danger" class="w-50" @click="errorModel = false">{{ $t("submit") }}</CButton>
            </div>
          </template>
        </CModal>
      </CRow>
    </form>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import util from "@/util/util";
import { mapGetters } from "vuex";
import local from "@/services/local";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import permis from "@/util/permission";
import uploadImage from "@/util/uploadImage";
import mime from "@/util/mime";
import ecommerce from "@/services/ecommerce";
import Multiselect from "vue-multiselect";
export default {
  components: {
    VueCropper,
    Multiselect
  },
  data() {
    const isEcommercePage =
      this.$route.path ===
      "/ecommerce/ProductList/plu/" + this.$route.params.productSKUObjectId;
    return {
      isEcommercePage: isEcommercePage,
      inventoryQuantity: 0,
      errorModel: false,
      isSuccess: false,
      SKUName: "",
      data: [],
      indexColor: 1,
      dataUnit: [],
      itemObjectId: [],
      PLUlist: [],
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      addPLUModal: false,
      imageData: "",
      colori: "",
      indexdata: "",
      unit: [],
      SKURatio: 1,
      SKUPrice: 0,
      productcode: "",
      selectcolor: false,
      imageDataBtn: "",
      SKU: [],
      mixedPrice: false,
      remoteImagePath: "",
      confirmModal: false,
      loadingSaveimg: true,
      titlename: "",
      showbuttonsave: false,
      itemid: "",
      genObjectId: "",
      loadingButton: true,
      countPLU: 0,
      itemimageData: "",
      itemimageDataBtn: "",
      itemremoteImagePath: "",
      isUpload: true,
      isload: false,
      deleteModal: false,
      loading: true,
      textError: "",
      textMessage: "",
      alertError: false,
      isError: 0,
      isMenu: false,
      imgTmpPath: "",
      shopeeData: [],
      itemIdShopee: "",
      modelId: "",
      showDetail: false,
      addDeliverlyPriceModal: false,
      itemDeleivery: {},
      details: [],
      checked: true,
      producrChannelList: [],
      salesChannel: [],
      isSaveNew: false,
      isAddNewMorePrice : false,
      isAddNewMorePriceSet : false,
      sellingTimelist : [],
      sellingTime :null
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    isPermission() {
      let path = "/product";
      return permis.findPermissionRead("product", path);
    },
    isEditData() {
      let path = "/product";
      const value = permis.findPermissionEdit("product", path);
      return value; // && this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      if (shop == null) {
        return false;
      }
      return shop.editableItem || false;
    },
    isDeleteData() {
      let path = "/product";
      return permis.findPermissionRemove("product", path);
    },

    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    colorList() {
      let data = [];
      for (let i = 0; i < 30; i++) {
        data.push({
          color: util.generateColor(i),
          value: i,
        });
      }
      return data;
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled !== undefined) {
          return this.shop.isMenuEnabled;
        } else {
          return false;
        }
      }
    },
    productSKUdetail: {
      get() {
        return this.$store.getters.productSKUdetail;
      },
      set() {
        return this.$store.getters.productSKUdetail;
      },
    },
    items() {
      const data = this.PLUlist || [];
      let detail = [];

      for (let i = 0; i < data.length; i++) {
        const item = data[i];

        const remoteImagePath = item.remoteImagePath || "";
        const name = item.name || "";
        const unit = item.unit || {};
        const isMenu = item.isMenu || false;
        const mixedPrice = item.mixedPrice || false;
        const isProductPLU1 = item.isProductPLU1 || false;

        const productPRU = item.ProductPRU || {};
        const price = util.convertCurrency(productPRU.exceedUc || 0);

        if (isProductPLU1 === false) {
          detail.push({
            remoteImagePath: remoteImagePath,
            name: name,
            unit: unit,
            SKURatio: item.SKURatio,
            PLUCode: item.PLUCode,
            price: price,
            colori: util.generateColor(item.indexColor),
            objectId: item.objectId,
            id: item.id,
            mixedPrice: mixedPrice,
            isMenu: isMenu,
            itemId: item.shopee?.item_id || "-",
            modelId: item.shopee?.model_id || "-",
          });
        }

        this.countPLU = detail.length;
      }
      return detail;
    },
    unitadd() {
      let data = this.dataUnit;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          if (data[i].name == "ชิ้น") {
            detail = {
              id: data[i].id,
              name: data[i].name,
              objectId: data[i].objectId,
            };
          }
        }
      }
      return detail;
    },
    unitlist() {
      let data = this.dataUnit;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          });
        }
      }
      return detail.sort(util.compare);
    },
  },
  created() {
    this.getProduct();
    this.getPLUlist();
    this.getUnit();
    this.getManageSaleHours()
    this.shop = this.shops.find((i) => i.objectId === this.shop);
  },
  mounted() {
    document.addEventListener("keydown", this.doSave);
  },
  methods: {
    findSellingTimeById(sellingTimeId) {
      // Check if sellingTimeId is provided
      if (!sellingTimeId) {
        return null;
      }

      // Search for the sellingTimeId in the sellingTimelist
      const result = this.sellingTimelist.find(item => item.id === sellingTimeId);

      if (result) {
        return result; // Return the matched object
      } else {
        return null; // Return null if not found
      }
    },
    getDifferent(item) {

      let difference = item - this.SKUPrice;
      return difference;
    },
    getManageSaleHours() {

        const shopObjectId = this.shopObjectId; // Retrieve shopObjectId
        const headers = { shopObjectId }; // Set headers

        local.get('/api/v3.0/sellingchannel/sellingtime', { headers })
            .then((res) => {

                if (res.status === 200) {
                    let data = res.data.data
                    this.sellingTimelist = data

                } else {
                    this.data = [];
                }
            })
            .catch((error) => {
                console.error("Error fetching sales hours data:", error);
                this.data = [];
            })
            .finally(() => {
                // Update loading states
                this.loading = false;
                this.loadingButton = true;
            });
    },
    getChannelByProductPLU(objectId) {

      let params = {
        productPLUObjectId: objectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        url: "/api/v3.0/sellingchannel/product/channel",
        params: params,
        headers: headers,
        method: "get",
      }).then((res) => {

        let data = res.data.data;
        this.producrChannelList = data;
        this.getSalesChannelsSetting();
      });
    },
    getChannelByProductPLUByObjectId() {
      let params = {
        productPLUObjectId: this.genObjectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        url: "/api/v3.0/sellingchannel/product/channel",
        params: params,
        headers: headers,
        method: "get",
      }).then((res) => {
        let data = res.data.data;
        this.producrChannelList = data;
        this.getSalesChannelsSetting();
      });
    },
    changeItemEdit(index) {
      this.salesChannel[index].isEdit = !this.salesChannel[index].isEdit;
    },
    updateProductPriceByChannel(item, index) {
      let sellingTime = this.sellingTime
      let objectId = this.itemDeleivery.objectId
      let SKUName = this.itemDeleivery.name 
      let sellingTimeId =  null
      if(sellingTime != null){
        sellingTimeId = sellingTime.id
      }else{
        sellingTimeId = null
      }
      if (this.itemDeleivery.objectId === undefined) {
        objectId = this.genObjectId
      }
      if (this.itemDeleivery.name === undefined) {
        SKUName = this.SKUName
      }
      
      let data = []
      if (item.id == '101') {
        data = {
          enabled: item.enabled,
          price: item.advancedPricing.deliveryOnDemand,
          productPRUType: {
            objectId: item.objectId,
            id: item.id,
            name: item.name
          },
          productPLU: {
            objectId: objectId,
            SKUName: SKUName,
            id: this.itemDeleivery.id
          },
          advancedPricing: item.advancedPricing,
          purchasability: item.purchasability,
          sellingTimeId: sellingTimeId,
        }
      } else {
        data = {
          enabled: item.enabled,
          price: item.price,
          productPRUType: {
            objectId: item.objectId,
            id: item.id,
            name: item.name
          },
          productPLU: {
            objectId: this.productSKUdetail.ProductPLU.objectId,
            SKUName: this.productSKUdetail.ProductPLU.name,
            id: this.productSKUdetail.ProductPLU.id
          }
        }
      }


      const shopObjectId = this.shopObjectId
      const headers = {
        shopObjectId: shopObjectId
      }

      local({
        method: 'POST',
        headers: headers,
        url: '/api/v3.0/sellingchannel/establish',
        data: data,
      })
        .then(() => {
          // this.salesChannel[index].isEdit = false
        })
        .catch((error) => {
          console.error(error)
        })
      
    },
    updateItemEdit(index) {
      let sellingTime = this.sellingTime
      let channel = this.salesChannel[index]
      let objectId = this.itemDeleivery.objectId
      let SKUName = this.itemDeleivery.name 
      let sellingTimeId =  null
      if(sellingTime != null){
        sellingTimeId = sellingTime.id
      }else{
        sellingTimeId = null
      }
      if (this.itemDeleivery.objectId === undefined) {
        objectId = this.genObjectId
      }
      if (this.itemDeleivery.name === undefined) {
        SKUName = this.SKUName
      }
      let data = {}
      if (channel.id == '101') {
        data = {
          enabled: channel.enabled,
          price: channel.advancedPricing.deliveryOnDemand,
          productPRUType: {
            objectId: channel.objectId,
            id: channel.id,
            name: channel.name
          },
          productPLU: {
            objectId: objectId,
            SKUName: SKUName,
            id: this.itemDeleivery.id
          },
          advancedPricing: channel.advancedPricing,
          purchasability: channel.purchasability,
          sellingTimeId: sellingTimeId,

        }
      } else {
        data = {
          enabled: channel.enabled,
          price: channel.price,
          productPRUType: {
            objectId: channel.objectId,
            id: channel.id,
            name: channel.name
          },
          productPLU: {
            objectId: objectId,
            SKUName: SKUName,
            id: this.itemDeleivery.id
          }
        }
      }


      try {
        const shopObjectId = this.shopObjectId;
        const headers = {
          shopObjectId: shopObjectId
        }

        local({
          method: 'post',
          url: '/api/v3.0/sellingchannel/establish',
          data: data,
          headers: headers,
        }).then((response) => {
          // console.log(response);
       
        })
      } catch (error) {
        console.log(error)
      }
      this.salesChannel[index].isEdit = !this.salesChannel[index].isEdit;
    },
    getSalesChannelsSetting() {

      let producrChannelList = this.producrChannelList;

      if(producrChannelList === undefined || producrChannelList.length === 0){
        producrChannelList  = []
      }
      const headers = { 
            shopObjectId: this.shopObjectId ,
      }
      let params = {
        shopObjectId: this.shopObjectId,
        type: "ALL",
      };
      local({
        url: "/api/v3.0/sellingchannel/establish",
        params: params,
        headers : headers,
        method: "get",
      }).then((res) => {
        let data = res.data.data;

        let advancedPricing = {
          deliveryOnDemand: this.SKUPrice,
          deliveryScheduled: this.SKUPrice,
          selfPickUpOnDemand:this.SKUPrice,
          dineInOnDemand: this.SKUPrice,
        };

        let purchasability = {
          deliveryOnDemand: false,
          deliveryScheduled: false,
          selfPickUpOnDemand: false,
          dineInOnDemand: false,
        };

        let sellingTimeId = null;

        let detail = [];

        for (let i = 0; i < data.length; i++) {
          let GP = "NO GP";
          let enabled = false;
          let price = this.SKUPrice;
          
          if (data[i].gpPercent !== undefined || data[i].gpPercent !== null) {
            if (data[i].gpPercent === null) {
              GP = "NO GP";
            } else {
              GP = util.convertCurrency(data[i].gpPercent);
            }
          }

          if (data[i].id == "1") {
            enabled = true;
          }
          let detailData = producrChannelList.find(
            (item) => item.ProductPRUType.id === data[i].id
          );

          if (detailData !== undefined) {

            enabled = detailData.ProductSKUChannel.enabled;
            price = detailData.ProductPRU.exceedUc;

            if (detailData.ProductPRUType.id === '101') {
                sellingTimeId = detailData.ProductPRU?.sellingTimeId ?? null;

                if (sellingTimeId) {
                  this.sellingTime = this.findSellingTimeById(sellingTimeId);
                }
                if (detailData.ProductPRU.purchasability !== undefined) {
                  purchasability = detailData.ProductPRU.purchasability
                }

                if (detailData.ProductPRU.advancedPricing !== undefined) {

                  if(detailData.ProductPRU.advancedPricing.deliveryOnDemand !== undefined){
                    advancedPricing.deliveryOnDemand = detailData.ProductPRU.advancedPricing.deliveryOnDemand
                  }

                  if(detailData.ProductPRU.advancedPricing.deliveryOnDemand !== undefined){
                    advancedPricing.deliveryOnDemand = detailData.ProductPRU.advancedPricing.deliveryOnDemand
                  }

                  if(detailData.ProductPRU.advancedPricing.deliveryScheduled !== undefined){
                    advancedPricing.deliveryScheduled = detailData.ProductPRU.advancedPricing.deliveryScheduled
                  }

                  if(detailData.ProductPRU.advancedPricing.dineInOnDemand !== undefined){
                    advancedPricing.dineInOnDemand = detailData.ProductPRU.advancedPricing.dineInOnDemand
                  }

                }
                }
          }

          if (data[i].id == "101") {
            detail.push({
              objectId: data[i].objectId,
              gpPercent: GP,
              id: data[i].id,
              name: data[i].name,
              orderIndex: data[i].orderIndex,
              typeGroup: data[i].typeGroup,
              img: util.getDeliveryImg(data[i].id),
              enabled: enabled,
              price: advancedPricing.deliveryOnDemand ,
              isEdit: false,
              advancedPricing: advancedPricing,
              purchasability: purchasability,
              sellingTimeId,
            });
          } else {
            detail.push({
              objectId: data[i].objectId,
              gpPercent: GP,
              id: data[i].id,
              name: data[i].name,
              orderIndex: data[i].orderIndex,
              typeGroup: data[i].typeGroup,
              img: util.getDeliveryImg(data[i].id),
              enabled: enabled,
              price: price,
              isEdit: false,
            });
          }
        }
        this.salesChannel = detail;
       
      });
    },
    addNewDeliveryPrice(){
      this.valtdateaddPLU()
      // this.addDeliverlyPriceModal = true; 
    },
    addDeliveryPrice(item) {
      this.addDeliverlyPriceModal = true;
    },
    productModel() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        itemId: this.itemIdShopee,
      };
      ecommerce({
        url: "/api/v1.0/shopee/product/models",
        params: params,
        method: "GET",
      })
        .then((response) => {
          const models = response.data.data.model;
          const matchedModel = models.find(
            (model) => model.model_id === this.modelId
          );
          if (matchedModel) {
            this.inventoryQuantity =
              matchedModel.stock_info_v2.summary_info.total_available_stock;
          } else {
            console.log("Model not found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveEcommerceInfo() {
      this.updatePrice();
      this.updateStockProduct();
    },
    updatePrice() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;

      const params = {
        shopObjectId: shopObjectId,
      };
      const data = {
        itemId: this.itemIdShopee,
        modelId: this.modelId,
        price: Number(this.SKUPrice),
      };
      ecommerce({
        url: "/api/v1.0/shopee/product/price",
        params: params,
        data: data,
        method: "PATCH",
      })
        .then((response) => {
          if (response.data.error.code != 0) {
            this.errorModel = true;
          } else {
            this.confirmModal = false;
            this.loadingSaveimg = true;
            this.addPLUModal = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateStockProduct() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
      };
      const data = {
        itemId: this.itemIdShopee,
        modelId: this.modelId,
        stock: Number(this.inventoryQuantity),
      };
      ecommerce({
        url: "/api/v1.0/shopee/product/stock",
        params: params,
        data: data,
        method: "PATCH",
      })
        .then((response) => {
          if (response.data.error.code != 0) {
            this.errorModel = true;
          } else {
            this.confirmModal = false;
            this.loadingSaveimg = true;
            this.addPLUModal = false;
          }
        })
        .catch((error) => {
          console.error("Error from check API", error);
        });
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE;
    },
    cancelUpdate() {
      if (this.imgTmpPath != "") {
        this.removeImage();
      }
      this.addPLUModal = false;
      this.showDetail = false;
    },
    doSave(e) {
      if (this.$router.app._route.name == "PLUType") {
        if (e.key === "Enter" && e.target.nodeName === "INPUT") {
          // button:not([disabled]),
          var focusableElementsString =
            'a[href], area[href], input:not([disabled]),select ,select:not([disabled]), textarea:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
          let arr = Array.from(
            e.target.form.querySelectorAll(focusableElementsString)
          );

          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === e.target) {
              let indexOf = i < arr.length - 1 ? arr[i + 1] : indexOf[0];
              indexOf.focus();
              break;
            }
          }
          e.preventDefault();
        }
      }
    },
    valtdateaddPLU() {
      if (this.productcode == "") {
        this.productcode = util.generateBarcode();
      }
      if (this.SKURatio < 0) {
        alert(this.$i18n.t("enterSKURatio") + " !!!");
      }
      if (this.SKUPrice < 0) {
        alert(this.$i18n.t("enterPrice") + " !!!");
      }
      if (this.SKUName == "") {
        alert(this.$i18n.t("enterProduct") + " !!!");
      }
      if (this.unit == "") {
        alert(this.$i18n.t("selectUnit") + " !!!");
      }
      if (
        this.productcode != "" &&
        this.SKURatio >= 0 &&
        this.SKUPrice >= 0 &&
        this.SKUName != "" &&
        this.unit != ""
      ) {
        this.alertError = false;
        this.loading = true;
        this.confirmModal = true;
      }
    },
    openAddPluModal() {
      this.itemDeleivery = {};
      this.showbuttonsave = true;
      this.SKUName = this.productSKUdetail.ProductPLU.name
      this.indexdata = 1;
      this.colori = util.generateColor(this.indexColor);
      this.SKUPrice = 0;
      this.productcode = "";
      this.SKURatio = 1;
      this.unit = this.unitadd;
      this.mixedPrice = false;
      this.isMenu = false;
      this.imageData = this.itemimageDataBtn;
      this.imageDataBtn = this.itemimageDataBtn;
      this.remoteImagePath = this.itemimageDataBtn;
      this.titlename = this.$i18n.t("addProductPLU");
      this.isSaveNew = false;
      this.isAddNewMorePrice = true
      this.sellingTime = null
      if (this.genObjectId == "") {
        this.generateObjectId();
      }
      this.getChannelByProductPLU(this.genObjectId)
      this.showDetail = true;
    },
    openEditPluModal(item, index) {
      this.itemDeleivery = item;
      this.indexdata = index;
      this.showbuttonsave = false;
      this.itemObjectId = item.objectId;
      this.itemid = item.id;
      this.colori = item.colori;
      this.SKUName = item.name;
      this.SKUPrice = util.currencyStringToNumber(item.price);
      this.productcode = item.PLUCode;
      this.unit = item.unit;
      this.SKURatio = item.SKURatio;
      this.isMenu = item.isMenu;
      this.mixedPrice = item.mixedPrice;
      this.itemIdShopee = item.itemId;
      this.modelId = item.modelId;
      this.sellingTime = this.findSellingTimeById(item.sellingTimeId)
      this.productModel();
      if (item.remoteImagePath != undefined) {
        this.imageData = item.remoteImagePath;
        this.imageDataBtn = item.remoteImagePath;
        this.remoteImagePath = item.remoteImagePath;
      }
      this.titlename = this.$i18n.t("editProductPLU");
      this.isSaveNew = true;
      this.isAddNewMorePrice = false
      this.getChannelByProductPLU(item.objectId)
      this.showDetail = true;
    },
    deletePLU(item) {
      this.loading = true;
      this.itemDelete = item;
      this.deleteModal = true;
    },
    deletePlu() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const plan = this.users.currentPlan;
      const shopObjectId = this.shopObjectId;
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.itemDelete.objectId,
      };
      const headers = { shopObjectId: shopObjectId };

      this.loading = false;
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/ProductPLU/softdelete",
        params: { plan: plan },
        headers: headers,
        data: data,
      })
        .then(
          setTimeout(() => {
            this.loading = true;
            this.deleteModal = false;
            this.getPLUlist();
          }, 1000)
        )
        .catch((error) => {
          console.log(error);
        });
    },
    getPLUlist() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      // let PDid = '66713cd51e137a0020d94eb4'
      let params = {
        //   skuObjectId: this.$route.params.productSKUObjectId,
        skuObjectId: this.$route.params.productSKUObjectId,
        includePLU1: true,
      };
      const headers = { shopObjectId: this.shopObjectId };

      local({
        url: "/api/v1.0/" + uid + "/productplu/getbysku",
        params: params,
        headers: headers,
        method: "GET",
      }).then((res) => {
        this.PLUlist = res.data.data;
      });
    },
    selectcolormodal() {
      this.selectcolor = true;
    },
    setindexcolor(index) {
      this.indexColor = index;
      this.colori = util.generateColor(index);
    },
    generateObjectId() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      local({
        method: "get",
        url: "/api/v1.0/" + uid + "/objectId/generate",
      })
        .then((res) => {
          this.genObjectId = res.data.data.objectId;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pickFile() {
      this.$refs.image.click();
    },
    savecolorimg() {
      this.loadingSaveimg = false;
      if (this.isload) {
        let cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.imageData = cropedImage;
        this.getImageURL();
      } else {
        this.selectcolor = false;
        this.loadingSaveimg = true;
      }
    },
    async getImageURL() {
      const uid = `${localStorage.getItem("shopsUid")}`;

      let ref = this.genObjectId;
      let image = this.imageData;

      let ext = mime.getExtension(image);

      if (ext == null) {
        alert("Only Support File Type Is JPEG, PNG");
      } else {
        this.remoteImagePath = await uploadImage.onUpload(uid, ref, image, ext);
        this.imgTmpPath = this.remoteImagePath;
        this.selectcolor = false;
        this.loadingSaveimg = true;
      }
    },
    uploadImage(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageDataBtn = event.target.result;
          this.$refs.cropper = this.imageDataBtn;
          this.isUpload = false;
          this.isload = true;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      if (this.genObjectId == "") {
        this.generateObjectId();
      }
    },
    async removeImage() {
      if (this.remoteImagePath != "") {
        await uploadImage.deleteImage(
          this.uid,
          this.shopObjectId,
          this.remoteImagePath
        );
      }
      this.imageData = "";
      this.imageDataBtn = "";
      this.remoteImagePath = "";
    },
    getUnit() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {
        page: 1,
        limit: 1000,
      };
      const shopObjectId = this.shopObjectId
      const headers = {
          shopObjectId: shopObjectId,
      }
      local
        .get("/api/v1.0/uid/" + uid + "/Unit/data", { params ,headers})
        .then((res) => {
          this.dataUnit = res.data.data;
        });
    },
    saveProductPLU() {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const plan = this.users.currentPlan;
      this.loadingSaveimg = false;
      let data = [];
      if (this.genObjectId != "") {
        data = {
          objectId: this.genObjectId,
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
          remoteImagePath: this.remoteImagePath,
        };
      } else {
        data = {
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
          remoteImagePath: this.remoteImagePath,
        };
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu });
      }
      this.loading = false;
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/productplu/add",
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
      })
        .then(
          (res) => {
          let item = res.data.data
            setTimeout(() => {
              this.confirmModal = false;
              this.loadingSaveimg = true;
              this.addPLUModal = false;
              this.getPLUlist();
              this.getProduct()
              this.titlename = this.$i18n.t("editProductPLU");
              this.itemDeleivery = item;
              this.isSaveNew = true;
              this.isAddNewMorePrice = false
              this.showDetail = true;
              this.getChannelByProductPLU(item.objectId)
            }, 1000)
          }
        )
        .catch((error) => {
          console.log(error);
        });
    },
    EditProductPLU() {
      this.loadingSaveimg = false;
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const plan = this.users.currentPlan;
      let data = [];
      if (this.remoteImagePath != "") {
        data = {
          id: this.itemid,
          objectId: this.itemObjectId,
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
          remoteImagePath: this.remoteImagePath,
        };
      } else {
        data = {
          id: this.itemid,
          objectId: this.itemObjectId,
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          loading: true,
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
        };
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu });
      }
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/productplu/add",
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
      })
        .then(
          setTimeout(() => {
            this.confirmModal = false;
            this.loadingSaveimg = true;
            this.addPLUModal = false;
            this.getPLUlist();
            this.getProduct();
          }, 1000)
        )
        .catch((error) => {
          console.log(error);
        });
    },
    getProduct() {
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: "",
      };

      local
        .get(
          "/api/v1.0/" +
          uid +
          "/ProductSKU/data/" +
          this.$route.params.productSKUObjectId,
          { params }
        )
        .then((res) => {
          let data = res.data.data.document;
          this.SKU = data;
          if (data.SKUName !== undefined) {
            this.SKUName = data.SKUName;
          }
          this.indexColor = data.indexColor
          this.colori = util.generateColor(data.indexColor);
          if (data.remoteImagePath != undefined) {
            this.itemimageData = data.remoteImagePath;
            this.itemimageDataBtn = data.remoteImagePath;
          }
          this.loadingButton = true;
        });
    },
    goback() {
      let productSKUObjectId = this.$route.params.productSKUObjectId;

      if (this.$route.path.includes("/product/productdetail/plu/")) {
        this.$router.push({
          name: "ProductDetail",
          params: { productSKUObjectId },
          query: {
            sort: this.$route.query.sort,
            page: this.$route.query.page,
            view: this.$route.query.view,
          },
        });
      } else if (this.$route.path.includes("/ecommerce/ProductList/plu/")) {
        this.$router.push({
          name: "EcommerceProductDetail",
          params: { productSKUObjectId },
          query: {
            sort: this.$route.query.sort,
            page: this.$route.query.page,
            view: this.$route.query.view,
          },
        });
      }
    },
  },
};
</script>

<style>
.product-body {
  border: 0.5px solid #29b289;
  border-radius: 5px;
  padding: 1rem;
  /* min-height: 120px; */
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #29b289;
  background-color: #29b289;
}

.cursor {
  cursor: pointer;
}

.limit-2 {
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .adcard{
    min-height: 600px;
    max-height: 600px;
} */
.pkhover:hover {
    -webkit-box-shadow: 0 6px 10px 0 rgb(0 0 0 / 16%);
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 16%);
    cursor: pointer;
}
</style>
