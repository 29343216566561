<template>
  <div id="mainProductDetailContainer" v-if="!isPermission">
    <!-- no access data -->
    <access-data></access-data>
  </div>
  <div id="mainProductDetailWrapper" v-else> 
    <CRow id="mainProductDetailRow">
      <!-- Tab Menu -->
      <CCol id="mainProductDetailTabMenuCol" lg="3" col="12" md="3" sm="12">
        <CCard id="mainProductDetailTabCard" class="shadow-sm">
          <CCardBody id="mainProductDetailTabBody">
          <CRow id="mainProductDetailBackRow">
              <CCol id="mainProductDetailBackCol" md="5" lg="5" sm="5" col="4" class="text-right mr-0">
                <CButton id="mainProductDetailBackButton" block color="light" @click="goback" class="align-items-center">
                      {{ $t("back") }}
                </CButton>
              </CCol>
              <CCol id="mainProductDetailShopCol" col="7" sm="7" md="7" lg="7" class="ml-0">
                <input
                  id="mainProductDetailShopInput"
                  type="text"  :label=" shop.shopName  - shop.branchName"
                  class="form-control" disabled
                  :placeholder="shop.shopName + '-' + shop.branchName "
                />
              </CCol>
           </CRow>
        </CCardBody>
        </CCard>
        <CCard id="mainProductDetailImageCard" class="shadow-sm" v-if="currentPath !== 'newAddproduct'">
          <CCardBody id="mainProductDetailImageBody">
          <div id="mainProductDetailImageRow" class="row justify-content-between">
              <div id="mainProductDetailImageName" class="col-lg-10 col-md-8 col-sm-9 col-10">
                <p class="font-weight-normal text-dark mb-0">
                  <span id="mainProductDetailImageSpan" style="font-size: medium">
                    <img id="mainProductDetailImage" v-if="productSKUdetail.remoteImagePath.length > 0" :src="productSKUdetail.remoteImagePath"
                      class="img-fluid rounded" width="15%"
                      onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/149/149071.png';" />
                    <CButton id="mainProductDetailImageButton" v-else :style="{ backgroundColor: productSKUdetail.colori }" 
                      style="" class="img-fluid">
                      <CIcon id="mainProductDetailImageIcon">
                        <i class="fas fa-square"></i>
                      </CIcon>
                    </CButton>
                    {{ productSKUdetail.SKUName }}  <img id="mainProductDetailSourceIcon" src="/img/delivery/grab.png" class="rounded-circle"
                    style="border-radius: 3px; width: 25px;" v-if="productSKUdetail.source === 'GRAB'"/>
                  </span>
                </p>
              </div>
              <div id="mainProductDetailDeleteCol" class="col-lg-1 col-md-4 col-sm-4 col-2 text-right" role="status" v-if="isDeleteData">
                <img id="mainProductDetailDeleteIcon" src="/img/trash.png" style="width: 18px; height: 18px; cursor: pointer;" v-c-tooltip="{
                  content: 'Delete <strong>' + this.productSKUdetail.SKUName + '</strong>',
                  placement: 'right',
                }" @click="confirmdelete()" />
              </div>
              <div id="mainProductDetailDisableDeleteCol" v-else class="col-md-3 col-sm-3 col-2 text-right">
                <img id="mainProductDetailDisableDeleteIcon" src="/img/trash.png"
                  style="width: 20px; height: 20px; cursor: not-allowed; opacity: 0.5; pointer-events: none;"
                  disabled />
              </div>
            </div>
          </CCardBody>
        </CCard>
        <CCard id="mainProductDetailTabButtonCard" class="shadow-sm" v-if="currentPath !== 'newAddproduct'">
          <CCardBody id="mainProductDetailTabButtonBody" style="padding: 0.5rem; margin-top: 7.5px; margin-bottom: 7.5px;text-align: justify;">
            <div id="mainProductDetailTabButtonGrid" class="d-grid gap-2 btn-mb p-2">
              <CButton id="mainProductDetailInfoButton" type="radio" class="col-lg-12  col-md-12 btn-tab  btn-bar-tab" variant="ghost" @click="goToTab('')"
                :class="[{active : currentPath === 'ProductDetail' ||currentPath === 'newAddproduct'}]"
                  style="border-radius: 5px;"  size="lg">
                  <CIcon id="mainProductDetailInfoIcon" name="cil-short-text" />  {{$t('information') }}
              </CButton>
              <CButton id="mainProductDetailOptionButton" type="radio" variant="outline" style=""
                class="col-lg-12 col-md-12  btn-tab btn-bar-tab"  @click="goToTab('AddOnProductDetail')" size="lg"
                :class="[{active : currentPath === 'AddOnProductDetail' ||currentPath === 'AddOnProductDetail'}]"
              >
                <CIcon id="mainProductDetailOptionIcon" name="cil-star" /> {{$t('txtOptionCategory') }} 
              </CButton>

              <CButton id="mainProductDetailBomButton" type="radio" variant="outline" class="col-lg-12 col-md-12  btn-tab btn-bar-tab " size="lg"
               @click="goToTab('BomProductDetail')"  v-if="productSKUdetail.skuType == 'BOM'"
              :class="[{active : currentPath === 'BomProduct' ||currentPath === 'BomProductDetail'}]"
                style="border-radius: 5px;">
               <CIcon id="mainProductDetailBomIcon" name="cil-library-add" />  {{ $t('productTypeBOM') }} 
              </CButton>
          
              <CButton id="mainProductDetailSerialButton" type="radio" variant="outline" class="col-lg-12  col-md-12  btn-tab btn-bar-tab "  size="lg"
              @click="goToTab('SerialProductDetail')"
               v-if="productSKUdetail.skuType == 'SN' || addProductSKUDetail.skuType == 'SN'"
              :class="[{active : currentPath === 'SerialProduct' ||currentPath === 'SerialProductDetail'}]"
                style="border-radius: 5px;">
                <CIcon id="mainProductDetailSerialIcon" name="cil-qr-code" />  {{ $t('productSN') }}
              </CButton>
 
              <CButton id="mainProductDetailPackageButton" type="radio" class="col-lg-12  col-md-12 btn-tab btn-bar-tab" variant="outline" 
              @click="goToTab('PackageProductDetail')" size="lg"
              v-if="productSKUdetail.skuType == 'P' && addProductSKUDetail.skuType == 'P'"
              :class="[{active : currentPath === 'PackageProduct' ||currentPath === 'PackageProductDetail'}]"
                style="border-radius: 5px;">
                <CIcon id="mainProductDetailPackageIcon" name="cil-tags" />  {{ $t('productPLU') }}
              </CButton>
              
              <CButton id="mainProductDetailPriceButton" type="radio" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab" variant="outline" size="lg"
              :class="[{active : currentPath === 'PriceProductDetail' ||currentPath === 'PriceProductDetail'}]"
              @click="goToTab('PriceProductDetail')">
                <CIcon id="mainProductDetailPriceIcon" name="cil-dollar" />  {{$t('pricePackage')}}
              </CButton>

              <CButton id="mainProductDetailDiscountButton" type="radio" class="col-lg-12 col-md-12  btn-tab  btn-bar-tab" variant="outline"  size="lg"
                 :class="[{active : currentPath === 'DiscountProduct' ||currentPath === 'DiscountProductDetail'}]"
                  style="border-radius: 5px;" 
                  @click="goToTab('DiscountProductDetail')">
                <CIcon id="mainProductDetailDiscountIcon" name="cil-calculator" />  {{$t('morePrice')}}
              </CButton>

            </div>
          </CCardBody>
        </CCard>
        <CCard id="mainProductDetailDisableCard" class="shadow-sm" v-else>
          <CCardBody id="mainProductDetailDisableBody" style="padding: 0.5rem; margin-top: 7.5px; margin-bottom: 7.5px;text-align: justify;">
             <div id="mainProductDetailDisableGrid" class="d-grid gap-2 btn-mb p-2">
              <CButton id="mainProductDetailDisableInfoButton" disable type="radio"  size="lg" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab active" variant="ghost"
                style="border-radius: 5px; font-size: medium">
                <CIcon id="mainProductDetailDisableInfoIcon" name="cil-short-text" />   {{$t('information') }}
              </CButton>
              <CButton id="mainProductDetailDisableOptionButton" type="radio" variant="outline" size="lg"
                style="border-radius: 5px; font-size: medium" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab" 
                @click="triggerAddProductMethodByPath('AddOnProductDetail')">
                <CIcon id="mainProductDetailDisableOptionIcon" name="cil-star" /> {{$t('txtOptionCategory') }} 
              </CButton>
              <CButton id="mainProductDetailDisableBomButton" type="radio" variant="outline" size="lg" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab"
                v-if="localSkuType == 'BOM' || localSkuType== 'BOM'"
                style="border-radius: 5px; font-size: medium" @click="triggerAddProductMethodByPath('BomProductDetail')">
               <CIcon id="mainProductDetailDisableBomIcon" name="cil-library-add" />  {{ $t('productTypeBOM') }}
              </CButton>
              <CButton id="mainProductDetailDisableSerialButton" type="radio" variant="outline" size="lg" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab" 
                @click="triggerAddProductMethodByPath('SerialProductDetail')"
                v-if="localSkuType == 'SN' || localSkuType == 'SN'"
                style="border-radius: 5px; font-size: medium">
                <CIcon id="mainProductDetailDisableSerialIcon" name="cil-qr-code" />  {{ $t('productSN') }}
              </CButton>
              <CButton id="mainProductDetailDisablePackageButton" type="radio" size="lg" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab" variant="outline" 
              @click="triggerAddProductMethodByPath('PackageProductDetail')"
               v-if="localSkuType == 'P' && localSkuType == 'P'"
                style="border-radius: 5px;">
                <CIcon id="mainProductDetailDisablePackageIcon" name="cil-tags" />  {{ $t('productPLU') }}
              </CButton>
              <CButton id="mainProductDetailDisablePriceButton" type="radio" size="lg" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab" variant="outline" 
              @click="triggerAddProductMethodByPath('PriceProductDetail')"
                style="border-radius: 5px; font-size: medium">
                <CIcon id="mainProductDetailDisablePriceIcon" name="cil-dollar" />  {{$t('pricePackage')}}
              </CButton>
              <CButton id="mainProductDetailDisableDiscountButton" type="radio" size="lg" class="col-lg-12 col-md-12 btn-tab  btn-bar-tab" 
              @click="triggerAddProductMethodByPath('DiscountProductDetail')"
                style="border-radius: 5px; font-size: medium">
                <CIcon id="mainProductDetailDisableDiscountIcon" name="cil-calculator" />  {{$t('morePrice')}}
              </CButton>
             </div>
          </CCardBody>
        </CCard>
      </CCol>

      <!-- Content Detail -->
      <CCol id="mainProductDetailContentCol" lg="9" md="9" sm="12">
        <CCard id="mainProductDetailContentCard" class="shadow-sm">
          <CCardBody id="mainProductDetailContentBody">
            <div id="mainProductDetailContentHeader" class="row mt-4  justify-content-center">
              <div id="mainProductDetailContentHeaderCol" class="col-md-10 col-sm-9 col-8">
                <h5 id="mainProductDetailContentHeaderTitle" class="font-weight-normal text-dark" style="font-size: large;">
                  <span id="mainProductDetailContentHeaderInfo" style="font-size: large;"
                    v-if="currentPath === '' || currentPath === 'ProductDetail' || currentPath === 'newAddproduct'"> {{
                      $t('information') }} </span>

                  <span id="mainProductDetailContentHeaderOption" style="font-size: large;" v-if="currentPath === 'AddOnProduct' || currentPath === 'AddOnProductDetail'"> {{$t('txtOptionCategory') }} 
                  </span>
                  <span id="mainProductDetailContentHeaderPackage" v-if="currentPath === 'PackageProduct' || currentPath === 'PackageProductDetail'">
                    {{ $t('productPLU') }}</span>
                  <span id="mainProductDetailContentHeaderDiscount" v-if="currentPath === 'DiscountProduct' || currentPath === 'DiscountProductDetail'">
                    {{$t('morePrice')}}</span>
                  <span id="mainProductDetailContentHeaderPrice" v-if="currentPath === 'PriceProduct' || currentPath === 'PriceProductDetail'">
                    {{$t('pricePackage')}}</span>
                  <span id="mainProductDetailContentHeaderSerial" v-if="currentPath === 'SerialProduct' || currentPath === 'SerialProductDetail'">
                    {{ $t('productSN') }}</span>
                  <span id="mainProductDetailContentHeaderBom" v-if="currentPath === 'BomProduct' || currentPath === 'BomProductDetail'"> {{ $t('productTypeBOM') }}</span>
                </h5>
              </div>
              <div id="mainProductDetailContentActionCol" class="col-md-2 col-sm-2 col-4 text-right" v-if="isEditData">
                <CButton id="mainProductDetailContentActionSaveButton" block color="success" @click="triggerAddProductMethod" v-if="currentPath === '' || currentPath === 'newAddproduct'">
                  {{ $t('save') }}
                </CButton>
                <CButton id="mainProductDetailContentActionDetailSaveButton" block color="success" @click="triggerProductDetailMethod" v-if="currentPath === 'ProductDetail'">
                  {{ $t('save') }}
                </CButton>
                <CButton id="mainProductDetailContentActionBomSaveButton" block color="success" @click="triggerProductBOMDetail" v-if="currentPath === 'BomProductDetail'">
                  {{ $t('save') }}
                </CButton>
                <CButton id="mainProductDetailContentActionDiscountButton" block color="info" @click="triggerProductDiscountDetailMethod" v-if="currentPath === 'DiscountProductDetail'">
                  {{ $t('addPrice') }}
                </CButton>
                <CButton id="mainProductDetailContentActionPackageButton" block color="info" @click="triggerProductPackageDetailMethod" v-if="currentPath === 'PackageProductDetail'">
                  {{ $t('addProductPLU') }}
                </CButton>
              </div>
            </div>
            <hr id="mainProductDetailContentDivider"/>
            <!-- Munu Detail -->
            <div id="mainProductDetailMenuDetail" class="overflow-scroll">
              <CCard id="mainProductDetailMenuCard" class="body-detail" style="    overflow-y: scroll;overflow-x :hidden ">
                <CCardBody id="mainProductDetailMenuBody" class="p-0" >
                  <div id="mainProductDetailMenuContent" v-if="productSKUObjectId != undefined">
                    <ProductInfo id="mainProductDetailProductInfo" v-if="routeName === 'ProductDetail'" :saveProductDetail="isSaveProduct"></ProductInfo>
                  </div>
                  <!-- <div style=" width: 100%"> -->
                    <AddNewProduct id="mainProductDetailAddNewProduct" :localSkuType="localSkuType" @updateSkuType="updateSkuType"  v-if="routeName === 'newAddproduct'"
                    :trigger="isTriggered"  :actionPage="actionPage" ref="AddProductComponent"></AddNewProduct>
                    <Discount id="mainProductDetailDiscount" v-if="currentPath === 'DiscountProductDetail'" ref="AddProductDiscountComponent"></Discount>
                    <BOMDetail id="mainProductDetailBomDetail" v-if="currentPath === 'BomProductDetail'" ref="editProductBOM" ></BOMDetail>
                    <PackageProduct id="mainProductPackageProduct"  v-if="currentPath === 'PackageProductDetail'" ref="AddProductPackageComponent"></PackageProduct>
                    <router-view></router-view>
                  <!-- </div> -->
                </CCardBody>
              </CCard>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <modal color="danger" id="mainProductDetailDeleteModal"  :show.sync="deleteModal" :title="$t('confirmDeleteProduct')" v-on:isSave="deleteProduct"
        centered :loading="loading">
        <br />
        <h4 id="mainProductDetailDeleteModalText" class="text-center">{{ $t('dialigDeleteProduct') }}</h4>
        <br />
      </modal>
    </CRow>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import local from "@/services/local";
import util from "@/util/util";
import "cropperjs/dist/cropper.css";
import permis from "@/util/permission";
import uploadImage from "@/util/uploadImage";
import router from "../../../router";
import ProductInfo from "./Information.vue";
import AddNewProduct from "./AddNewProduct.vue";
import Discount from "./Discount.vue"
import BOMDetail from "./Bom.vue"
import PackageProduct from "./Package.vue";

export default {
  components: {
    ProductInfo,
    AddNewProduct,
    Discount,
    BOMDetail,
    PackageProduct
  },
  data() {
    return {
      SKUPrice: 0,
      category: null,
      unit: null,
      dataCategory: [],
      image: {},
      remoteImagePath: "",
      shop: this.$store.getters.shopObjectId,
      objectSKUId: "",
      loading: true,
      isMenu: false,
      objectId: "",
      isNew: false,
      deleteModal: false,
      confirmModal: false,
      alertError: false,
      isTriggered: false,
      isSaveProduct: false,
      addProductDiscount: false,
      actionPage : '',
      localSkuType : 'P',
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    currentPath() {
      return this.$route.name;
    },
    isPermission() {
      let path = "/product";
      return permis.findPermissionRead("product", path);
    },
    isSelfOrderEnabled() {
      return permis.findPermissionRead("selfOrder", this.$route.path);
    },
    isEditData() {
      let path = "/product";
      const value = permis.findPermissionEdit("product", path);
      return value; // && this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.editableItem || false;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    productSKUdetail: {
      get() {
        return this.$store.getters.productSKUdetail;
      },
      set() {
        return this.$store.getters.productSKUdetail;
      },
    },
    addProductSKUDetail: {
      get() {
        return this.$store.getters.addProductSKUDetail
      },
      set(newValue) {
        return this.$store.dispatch('SET_NEW_PRODUCT_SKU_DETAIL', newValue)
      }
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    sortdata() {
      return this.$route.query.sort || 'SKUName'
    },
    showView() {
      return this.$route.query.view || "grid";
    },
    page() {
      return this.$route.query.page || 1;
    },
    searchBy() {
      return this.$route.query.searchBy || "";
    },
    keyword() {
      return this.$route.query.keyword || "";
    },
    queryURL() {
      return (
        "?sort=" +
        this.sortdata +
        "&page=" +
        this.page +
        "&view=" +
        this.showView +
        "&searchBy=" +
        this.searchBy +
        "&keyword=" +
        this.keyword
      );
    },
    productSKUObjectId() {
      return this.$route.params.productSKUObjectId;
    },
    routeName() {
      return this.$route.name;
    },
    isDeleteData() {
      let path = '/product'
      return permis.findPermissionRemove('product', path)
    },
  },
  created() {
    this.$store.getters.productSKUdetail;
    this.$store.dispatch("setProductID", this.productSKUObjectId);
    this.$store.dispatch("getProductSKUDatail");
    this.$store.dispatch("setNewItem");
    this.shop = this.shops.find((i) => i.objectId === this.shop);
    this.objectId = util.generateObjectId();
  },
  mounted() {
    document.addEventListener("keydown", this.doSave);
  },
  methods: {
    updateSkuType(newValue) {
      this.localSkuType = newValue; // Update skuType from child
    },
    triggerProductPackageDetailMethod(){
      this.$refs.AddProductPackageComponent.openAddPluModal();
    },
    triggerProductDiscountDetailMethod(){
      this.$refs.AddProductDiscountComponent.addproductPRU();
    },
    triggerProductBOMDetail(){
      this.$refs.editProductBOM.confirmSaveProductBOM()
    },
    triggerAddProductMethod() {
       this.isTriggered = true;
      setTimeout(() => {
        this.isTriggered = false;
      }, 100);
    },
    triggerAddProductMethodByPath(item){
       this.actionPage = item
       this.triggerAddProductMethod()
    },
    triggerProductDetailMethod(){
      this.isSaveProduct = true
      setTimeout(() => {
        this.isSaveProduct = false;
      }, 100);
    },
    goback() {
      this.$router.push("/product" + this.queryURL);
    },
    confirmdelete() {
      this.deleteModal = true
    },
    deleteProduct() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.productSKUObjectId,
      }
      const headers = { shopObjectId: shopObjectId }

      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductSKU/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.deleteModal = false
            this.$router.push('/product' + this.queryURL)
          }, 100)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    goToTab(link) {
      let name = this.$route.name;
      
      let productSKUObjectId = this.$route.params.productSKUObjectId;
      // + this.queryURL
      
      if (
        name === "ProductDetail" ||
        name === "BomProductDetail" ||
        name === "DiscountProductDetail" ||
        name === "PackageProductDetail" ||
        name === "PriceProductDetail" ||
        name === "AddOnProductDetail" ||
        name === "BomProductDetail" ||
        name === "BomProductDetail" ||
        name === "SerialProduct" ||
        name === "SerialProductDetail"
      ) {
        if (link === "") {
          this.$router.push({
            name: "ProductDetail",
            params: { productSKUObjectId },
            query: {
              sort: this.$route.query.sort,
              page: this.$route.query.page,
              view: this.$route.view,
              searchBy: this.searchBy,
              keyword: this.keyword,
            },
          });
          // this.$router.push("/product/productdetail/" + productSKUObjectId);
        } else {
          this.$router.push({
            name: link,
            params: { productSKUObjectId },
            query: {
                sort: this.$route.query.sort,
                page: this.$route.query.page,
                view: this.$route.view,
                searchBy: this.searchBy,
                keyword: this.keyword,
              }
          });
          // this.$router.push(
          //   "/product/productdetail/" + link + "/" + productSKUObjectId
          // );
        }
      } else {
        if (link === "") {
          this.$router.push({
            name: "newAddproduct",
            query: {
              sort: this.$route.query.sort,
              page: this.$route.query.page,
              view: this.$route.view,
              searchBy: this.searchBy,
              keyword: this.keyword,
            }
          });
          // this.$router.push("/product/newAddproduct");
        } else {
          this.$router.push({
            name: link,
            params: { productSKUObjectId },
            query: {
              sort: this.$route.query.sort,
              page: this.$route.query.page,
              view: this.$route.view,
              searchBy: this.searchBy,
              keyword: this.keyword,
            },
          });
        }
      }
    },
    async cancel() {
      if (this.remoteImagePath !== "") {
        await uploadImage.deleteImage(
          this.uid,
          this.shopObjectId,
          this.remoteImagePath
        );
      }
      router.push("/product");
    },
    generateObjectId() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        method: "get",
        headers: headers,
        url: "/api/v1.0/" + uid + "/objectId/generate",
      })
        .then((res) => {
          this.objectId = res.data.data.objectId;
        })
        .catch((error) => {
          console.log(error);
        });
    },
   
    validator(val) {
      return val ? val.length >= 1 : false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.btn-tab {
  color: #4b5f71;
  border-color: #4b5f71;
  margin: 5px;
  text-align: justify;
  border-radius: 5px;
  border: 0.5px solid #d8dbe0;
}

.btn-tab:not(:disabled):not(.disabled):active,
.btn-tab:not(:disabled):not(.disabled).active,
.show>.btn-tab.dropdown-toggle {
  color: #fff;
  background-color: #29b289;
  border-color: #29b289;
}

.btn-tab:hover {
  color: #fff;
  background-color: #29b289;
  border-color: #29b289;
}

.btn-tab:not(:disabled):not(.disabled).active {
  color: #fff;
  border-color: #29b289;
  /* margin: 5px; */
  text-align: justify;
  border-radius: 5px;
  border: 0.5px solid #29b289;
}

.btn-tab:active {
  color: #fff;
  border-color: #29b289;
  /* margin: 5px; */
  text-align: justify;
  border-radius: 5px;
  border: 0.5px solid #29b289;
}

.btn-tab:hover {
  color: #fff;
  border-color: #29b289;
  /* margin: 5px; */
  text-align: justify;
  border-radius: 5px;
  border: 0.5px solid #29b289;
}

.btn-tab.check {
  color: #29b289;
  border-color: #29b289;
  /* margin: 5px; */
  text-align: justify;
  border-radius: 5px;
  border: 0.5px solid #29b289;
}

/* Custom CSS for Pill Tabs */
.cpills-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.cpill {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 50px;
  margin: 0 0.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.cpill:not(.active) {
  background-color: #e9ecef;
  color: #495057;
}

.cpill.active {
  background-color: #0d6efd;
  color: white;
}

.cpill:hover {
  background-color: #0d6efd;
  color: white;
}

.body-detail {
  min-height: 500px;
  max-height: 500px;
}

@media(min-height: 600px){
  .body-detail {
    min-height: 450px;
    max-height: 450px;
  }
}
@media(min-height: 700px){
  .body-detail {
    min-height: 450px;
    max-height: 450px;
  }

}
@media(min-height: 800px){
  .body-detail {
    min-height: 500px;
    max-height: 500px;
  }
}

@media(min-height: 900px){
  .body-detail {
    min-height: 670px;
    max-height: 670px;
  }
}

@media(min-height: 1000px){
  .body-detail {
    min-height: 800px;
    max-height: 800px;
  }
}

.btn-bar-tab{
   font-size: medium
}
@media(max-width: 500px){
  .btn-bar-tab{
    width: auto;
    font-size: small;
  }
}
@media(max-width: 1000px){
  .btn-bar-tab{
    font-size: medium;
  }
}
@media(max-width: 1200px){
  .btn-bar-tab{
    font-size: small;
  }
}
@media(min-width: 1300px){
  .btn-bar-tab{
    font-size: medium;
  }
}


.btn-mb {
  /* margin-bottom: -5px; */
}
</style>
<style src="../../../assets/styles/imgProduct.css"></style>
