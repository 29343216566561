<template>
  <div v-if="isPermission && isEditData">
    <CCol md="12" lg="12">
      <form>

            <CAlert color="danger" :show.sync="isError" closeButton id="error-alert">
              {{ textError }} - {{ textMessage }}
            </CAlert>
            <CAlert color="success" v-if="isSuccess === true" id="success-alert">
              {{ $t('createProductSuccess') }}
            </CAlert>
           
            <!-- <br /> -->
            <CRow md="12" sm="12" class="form-group row">
              <CCol md="2" sm="2" class="imagelabel">
                <label class="col-form-label  text-dark mt-3 mb-2" id="product-img-label">
                  {{ $t('productImg') }}
                </label>
              </CCol>

              <CCol md="4" sm="10">
                <div v-if="imageData.length > 0" style="
                    text-align: center;
                    box-shadow: 0 0 0 1px #e5e5e5;
                    border-radius: 0.2rem;
                    width: 180px;
                  ">
                  <div class="profile-img doc-img">
                    <div class="profile-widget">
                      <img :src="imageData" class="img-fluid" alt="User Image" @click="selectcolormodal" id="product-img"/>
                      <a @click="deleteImg()" class="fav-btn" id="delete-img-button">
                        <img src="/img/trash.png" style="width: 14px; height: 14px;cursor: pointer; "/>
                      </a>
                    </div>
                  </div>
                </div>
                <div v-else style="
                    text-align: center;
                    box-shadow: 0 0 0 1px #e5e5e5;
                    border-radius: 0.2rem;
                    width: 180px;
                  ">
                  <CButton :style="{ backgroundColor: colori }" @click="selectcolormodal"
                    style="width: 100%; height: 136px" class="img-fluid" id="select-color-button">
                    <CIcon>
                      <i class="fas fa-square"></i>
                    </CIcon>
                  </CButton>
                </div>
              </CCol>
              <!-- <CCol v-if="isSelfOrderEnabled" md="2" class=" col-form-label text-dark mt-3 imagelabel " id="additional-img-label"><label
                  v-html="$t('additionalImage')"></label>
              </CCol> -->
              <CCol v-if="isSelfOrderEnabled" md="6" class="mb-2">
                <CButton key="add-video" @click="selectVideo()"
                  :style="{ position: 'relative', width: '55px', height: '55px', margin: '2px', backgroundColor: currentVideo !== '' ? '#3abc98' : '#d8dbe0', color: '#878787', fontSize: 'medium', overflow: 'hidden' } " id="add-video-button"
                  class="img-fluid">
                  <!-- Video -->
                  <div v-if="currentVideo !== ''"
                    style="display: flex; align-items: center; justify-content: center;color: white;">
                    <i class="fi fi-rr-film"></i>
                  </div>
                  <div v-else style="display: flex; align-items: center; justify-content: center;">
                    <i class="fi fi-rr-film"></i>
                  </div>
                </CButton>
                <CButton v-for="index in additionImageAmount - 1" :key="index" @click="selectAdditionalImg(index)"
                  style="position: relative; width: 55px; height: 55px; margin: 2px; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                  class="img-fluid"
                  id="additional-img-button">
                  <!-- Image -->
                  <img v-if="localAdditionImagePath[index] !== undefined && localAdditionImagePath[index] !== null"
                    :src="localAdditionImagePath[index]"
                    style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                  <!-- '+' placeholder -->
                  <div v-else style="display: flex; align-items: center; justify-content: center;">+</div>
                </CButton>
                <label
                  v-html="$t('additionalImage')" class="col-form-label text-dark mt-0  mb-0"></label>
                <p style="font-size: small;" class="text-grey text-small   mb-0">*{{ $t('useInQr') }}</p>
              </CCol>
            </CRow>
            <CModal color="success" :show.sync="uploadVideoModal" :title="$t('uploadVideo')" centered id="upload-video-modal">
              <CRow style="justify-content: center;" v-if="videoOption == ''">
                <CCol md="5" sm="10" @click="videoOption = 'Upload'" class="text-center pt-5 pb-5  m-2"
                  style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                  <i class="fi fi-rr-upload" style="font-size:xx-large;"
                  id="upload-video-option"></i> <br>
                  <p class="mt-2">{{ $t('uploadVideo') }}</p>
                </CCol>
                <CCol md="5" sm="10" @click="videoOption = 'URL'" class="text-center pt-5 pb-5  m-2"
                  style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;" id="url-video-option">
                  <i class="fi fi-rr-play" style="font-size:xx-large;"></i><br>
                  <p class="mt-2"> Youtube URL</p>
                </CCol>
              </CRow>

              <CRow v-if="videoOption == 'Upload'" id="video-upload-row">
                <label v-if="currentVideoHolder !== ''" class="col text-right pr-4 mb-4" style="color:red;" id="delete-video-button"
                  @click="delteVideo()"> <i class="fi fi-rr-trash"></i> {{ $t('deleteVideo') }}
                </label>
                <video v-if="currentVideoHolder !== ''" style="width: 100%; height: 100%; " :src="currentVideoHolder" id="video-preview"
                  controls></video>

                <label class="col-12 ">
                  <div v-if="currentVideoHolder == ''" class="text-center pt-5 pb-3"
                    style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;" id="video-upload-placeholder">
                    <i class="fi fi-rr-upload" style="font-size:xx-large;"></i> <br>
                    <p class="mt-2">{{ $t('uploadVideo') }}</p>
                    <p class="text-danger">*{{ $t('videoSizeNotTooBig') }}</p>
                    <input style="visibility: hidden;" ref="video" type="file" accept="video/*"
                      @change="handleVideoFileChange($event.target)" id="video-file-input"/>
                  </div>
                </label>

              </CRow>
              <CRow v-if="videoOption == 'URL'">
                <LazyYoutube v-if="currentVideoHolder !== ''" :src="currentVideoHolder" />
                <label class="col-12 mt-3" id="url-input-label">{{ $t('pasteURL') }} <small style="color: rgb(180, 180, 180)">*Ex.
                    https://youtu.be/sRxrwjOtIag?si=3yfcN0XRXnsJlQxM</small> </label>
                <CCol md="12" sm="12">
                  <CInput v-model="currentVideoHolder" id="url-input"></CInput>
                </CCol>
              </CRow>
              <template #footer-wrapper>
                <div></div>
                <CRow class="p-3" v-if="videoOption !== ''" id="upload-video-footer">
                  <CCol>
                    <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="saveVideo()" id="save-video-button">
                      {{ $t('save') }}
                    </CButton>
                    <CButton block color="success" v-else-if="loadingSaveimg === false" disabled id="loading-save-video-button">
                      <CSpinner color="white" size="sm" /> {{ $t('save') }}
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton block color="light" @click="delteVideo()" id="cancel-video-button">
                      {{ $t('cancel') }}
                    </CButton>
                  </CCol>
                </CRow>
              </template>
            </CModal>
            <CModal color="success" :show.sync="additionalModal" :title="$t('selectImg')" centered id="additional-img-modal">
              <div class="row">
                <label class="col-6 pl-3" id="select-img-pc-label">{{ $t('selectImgFromPC') }}
                  <input v-show="currentImg == '' || currentImg == null" class="mt-2" ref="fileInput" style=""
                    type="file" accept="image/*" @change="handleFileChange($event.target)" id="additional-img-input"/>
                </label>
                <label v-if="currentImg !== '' && currentImg !== null" class=" col-6 text-right pr-4"
                  style="color: red;" @click="deleteLocalImg()" id="delete-additional-img"> <i class="fi fi-rr-trash"></i>{{
                    $t('deleteImg') }}
                </label>
              </div>
              <VueCropper v-if="currentImg !== '' && currentImg !== null" :guides="true" :aspectRatio="1.3"
                :initialAspectRatio="1.3" ref="cropper2" :src="getCurrentImage()" alt="Source Image" :zoomable="false"
                :scalable="false" id="img-cropper">
              </VueCropper>

              <template #footer-wrapper id="additional-img-footer">
                <CRow class="p-4">
                  <CCol>
                    <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="saveAdditionalImg" id="save-additional-img">
                      {{ $t('save') }}
                    </CButton>
                    <CButton block color="success" v-else-if="loadingSaveimg === false" disabled id="loading-save-additional-img">
                      <CSpinner color="white" size="sm" /> {{ $t('save') }}
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton block color="light" @click="additionalModal = false" id="cancel-additional-img">
                      {{ $t('cancel') }}
                    </CButton>
                  </CCol>
                </CRow>
              </template>
            </CModal>
            <CRow>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-md-right text-left text-dark w-100 w-sm-auto" id="product-name-label">
                  <b class="text-danger">*</b> {{ $t('productName') }}
                </label>
                <div v-if="validateSKUName" class="col-sm-10">
                  <CInput v-model="SKUName" name="SKUName" :placeholder="$t('productName')" required
                    :is-valid="validator" :invalid-feedback="$t('enterProduct')" id="product-name-input"/>
                </div>
                <div v-else class="col-sm-10">
                  <CInput v-model="SKUName" name="SKUName" :placeholder="$t('productName')" required id="product-name-input"/>
                </div>
              </CCol>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-md-right text-left text-dark w-100 w-sm-auto" id="product-price-label">
                  <b class="text-danger">*</b> {{ $t('price') }}
                </label>
                <div v-if="validateSKUPrice" class="col-sm-10">
                  <CInput v-model.number="SKUPrice" :placeholder="$t('price')" min="0" type="number"
                    :is-valid="SKUPrice !== null && SKUPrice !== '' && SKUPrice >= 0"
                    :is-invalid="SKUPrice === null || SKUPrice === ''"
                    :invalid-feedback="$t('priceMustBeGreaterThanZero')" id="product-price-input" />
                </div>
                <div v-else class="col-sm-10">
                  <CInput v-model.number="SKUPrice" :placeholder="$t('price')" min="0" type="number"
                    :is-valid="SKUPrice !== null && SKUPrice !== '' && SKUPrice >= 0"
                    :is-invalid="SKUPrice === null || SKUPrice === ''"
                    :invalid-feedback="$t('priceMustBeGreaterThanZero')" id="product-price-input" />
                </div>
              </CCol>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
                  {{ $t('cost') }}
                </label>
                <div v-if="validateSKUPrice" class="col-sm-10">
                   <input min="0" v-model.number="stdCost" type="number"
                        :class="['form-control', 'text-right', { 'text-danger': stdCost > SKUPrice && SKUPrice > 0 }]"
                        :disabled="skuType == 'BOM'" />
                      <div class="d-flex justify-content-end">
                        <span v-if="stdCost > SKUPrice && SKUPrice > 0" class="text-danger text-right"
                          style="font-size: 10px;">
                          * {{ $t('stdCostWarning') }}
                        </span>
                      </div>
                </div>
                <div v-else class="col-sm-10">
                  <CInput v-model.number="stdCost" :placeholder="$t('price')" min="0" type="number"
                    :is-valid="stdCost !== null && stdCost !== '' && stdCost >= 0"
                    :is-invalid="stdCost === null || stdCost === ''"
                    :invalid-feedback="$t('priceMustBeGreaterThanZero')" />
                </div>
              </CCol>
              <CCol md="12" class=" row">
                <label class="col-sm-2 col-form-label text-md-right text-left text-dark w-100 w-sm-auto" id="plu-code-label">
                  {{ $t('pluCode') }}
                </label>
                <div class="col-sm-10">
                  <CInput v-model="productcode" :placeholder="$t('pluCode')" id="plu-code-input"/>
                </div>
              </CCol>
              <CCol md="12" sm="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-md-right text-left text-dark w-100 w-sm-auto" id="category-label">
                  <b class="text-danger">*</b> {{ $t('category') }}
                </label>
                <div class="col-sm-10">
                  <multiselect v-model="category" :options="categorylist" :placeholder="$t('category')" label="name" id="category-select">
                  </multiselect>
                  <span class="text-danger" v-show="category == null">{{ this.validateCategory }}</span>
                </div>
              </CCol>
              <CCol md="12" sm="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-md-right text-left text-dark w-100 w-sm-auto" id="unit-label">
                  <b class="text-danger">*</b> {{ $t('unit') }}
                </label>
                <div class="col-sm-10">
                  <multiselect v-model="unit" :options="unitlist" :placeholder="$t('unit')" label="name" id="unit-select"
                    track-by="name">
                  </multiselect>
                  <span class="text-danger" v-show="unit == null" id="unit-error">{{ $t('selectUnit') }}</span>
                </div>
              </CCol>
                <CCol  md="12" sm="12" class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label text-right text-dark">
                    {{ $t('productDes') }}
                  </label>
                  <div class="col-sm-10 col-8">
                    <textarea 
                      v-model="productDescription" 
                      class="form-control" 
                      rows="5" 
                      @input="validateDescription" 
                      :placeholder="$t('fillProductDetail')"
                    ></textarea>
                    <small v-if="productDescription.length < 60" class="text-grey">
                      ({{ productDescription.length }}/5000)
                    </small>
                  </div>
                </CCol>
            </CRow>
 

            <div class="row mt-2">
              <div class="col-md-12 col-12">
                <p class="font-weight-normal" id="product-type-header" style="font-size: large;"> {{ $t('productType') }}</p>
              </div>
            </div>
            <CCol sm="12" lg="12" md="12" class="row justify-content-end">
              <!-- <CRow> -->
              <CCol md="8" sm="6" lg="10" col="12">
                <CRow>
                  <CCol sm="6" col="6" class="form-group" id="vat-type-checkbox">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="vatType" id="vatType"
                        true-value="V" false-value="N" />
                      <label class="custom-control-label cursor" for="vatType" id="vatType-label">
                        {{ $t('netTotalIncVAT') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group" id="favorite-checkbox">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="favorite" 
                      id="favorite-input"
                         />
                      <label class="custom-control-label cursor" for="favorite-input" id="favorite-label">
                        {{ $t('favorite') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group" id="service-charge-checkbox">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="serviceCharge"
                      id="serviceCharge-input" />
                      <label class="custom-control-label cursor" for="serviceCharge-input" id="serviceCharge-label">
                        {{ $t('serviceCharge') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group" id="on-screen-checkbox">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="isOnScreen" id="isOnScreen-input" 
                        />
                      <label class="custom-control-label cursor" id="isOnScreen-label"  for="isOnScreen-input">
                        {{ $t('isOnScreen') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="negotiatePrice"
                      id="isnegotiatePrice-input" />
                      <label class="custom-control-label cursor" for="isnegotiatePrice-input" id="isnegotiatePrice-label">
                        {{ $t('negotiatePrice') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group" v-if="isMenuEnabled">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" id="isMenu-input" v-model="isMenu" />
                      <label class="custom-control-label cursor" for="isMenu-input" id="isMenu-label">
                        {{ $t('isMenu') }}</label>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol lg="12" md="12" sm="12">
                <div class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label text-right text-dark mt-3" id="product-type-label">
                    <b class="text-danger">*</b> {{ $t('productType') }}
                  </label>
                  <div class="col-sm-10 col-8">
                    <select class="custom-select mt-3" v-model="skuType" id="product-type-select" @change="emitSkuType">
                      <option value="P" selected :disabled="isEcommercePage">
                        {{ $t('productTypeP') }}
                      </option>
                      <option v-if="!isEcommercePage" value="BOM">
                        {{ $t('productTypeBOM') }}
                      </option>
                      <option v-if="!isEcommercePage" value="SN">{{ $t('productTypeSN') }}</option>
                      <option v-if="!isEcommercePage" value="SV">{{ $t('productTypeSV') }}</option>
                    </select>
                  </div>
             
                </div>
              </CCol>
              <!-- </CRow> -->
            </CCol>

        <CCard v-show="isEcommercePage" class="shadow-sm">
            <CCardBody>
            <div class="row">
                <div class="col-md-10 col-10">
                  <h4 class="font-weight-normal text-dark">
                    {{ $t('inventoryTxt') }}
                  </h4>
                </div>
              </div> 
              <CRow class="justify-content-between m-2">
                <CCol col="12">
                  <CRow class="mb-3">
                    <CCol col="2" class="text-right">
                      <label class="mt-2"><span class="text-danger mr-1">*</span>{{ $t('inventoryQuantity') }}</label>
                    </CCol>
                    <CCol col="10">
                      <CInput class="text-right" v-model="pluStock" :placeholder="$t('')" />
                    </CCol>
                    <!-- <CCol col="2">
                      <i class="fas fa-pen-square fa-2x cursor" @click="EditStockModal = true" width="30"
                      style="color: #3abc98"></i>                    
                    </CCol> -->
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

        <CCard v-show="isEcommercePage">
          <CCardBody>
            <h4 class="font-weight-normal text-dark">{{ $t('shipping') }}</h4>

            <!-- น้ำหนัก -->
            <CRow class="mb-3 align-items-center">
              <CCol col="2" class="text-right">
                <label><span class="text-danger mr-1">*</span>{{ $t('weight') }}</label>
              </CCol>
              <CCol col="3">
                <CInput v-model="weight" placeholder="0" />
              </CCol>
              <CCol col="1" class="text-left">
                <label>kg</label>
              </CCol>
            </CRow>

            <!-- ขนาดพัสดุ -->
            <CRow class="mb-3 align-items-center">
              <CCol col="2" class="text-right">
                <label>{{$t('parcelSize')}}</label>
              </CCol>
              <CCol col="2">
                <CInput v-model="width" placeholder="ก" />
              </CCol>
              <CCol col="1">
                <label>cm</label><i class="fi fi-rr-x ml-3"></i>
              </CCol>
              <CCol col="2">
                <CInput v-model="length" placeholder="ย" />
              </CCol>
              <CCol col="1">
                <label>cm</label> <i class="fi fi-rr-x ml-3"></i>
              </CCol>
              <CCol col="2">
                <CInput v-model="height" placeholder="ส" />
              </CCol>
              <CCol col="1">
                <label>cm</label>
              </CCol>
            </CRow>

            <!-- ค่าจัดส่ง -->
            <!-- <CRow>
              <CCol col="2" class="text-right mt-3">
                <label>{{$t('shippingFee')}}</label>
              </CCol>
              <CCol col="10">
              
              <CRow
                v-for="(logistic, index) in logistic_info"
                :key="logistic.logistic_id"
                class="mb-3 align-items-center"
              >
                <CCol col="3">
                  <span>{{ logistic.logistic_name }}</span>
                </CCol>
                <CCol col="1">
                  <CInput v-model="logistic_info" disabled/>
                </CCol>
                <CCol col="1">
                  <label>฿</label>
                </CCol>
                <CCol col="2">{{ $t('enable') }} :
                </CCol>
                <CCol col="1">
                  <CSwitch color="success" :checked.sync="logisticInfo" />
                </CCol>
                <CCol col="2">{{ $t('shopCoverShipping') }} :
                </CCol>
                <CCol col="1">
                  <CSwitch color="success" :checked.sync="shippingFree" />
                </CCol>
              </CRow> 
            </CCol>
          </CRow> -->
            <p class="text-muted" style="margin-left: 80px;">{{ $t('shippingWarn') }}</p>
          </CCardBody>
        </CCard>

        <CCard v-show="isEcommercePage">
          <CCardBody>
            <h4 class="font-weight-normal text-dark">{{ $t('others') }}</h4>

            <!-- เตรียมส่งมากกว่าปกติ -->
            <CRow class="mb-3 align-items-center">
              <CCol col="3" class="text-right">
                <label>{{ $t('preOrder') }}</label>
              </CCol>
              <CCol col="4">
                <div>
                  <input  type="radio" id="notReady" value="no" v-model="readyToShip" />
                  <label for="notReady">{{ $t('no') }}</label>

                  <input  type="radio" id="ready" value="yes" v-model="readyToShip" class="ml-3" />
                  <label for="ready">{{ $t('yes') }}</label>
                </div>
              </CCol>
            </CRow>

            <!-- ข้อความอธิบาย -->
            <CRow class="mb-3">
              <CCol col="9" class="offset-3">
                <p class="text-muted">
                {{ $t('otherDes') }}
                </p>
              </CCol>
            </CRow>

            <!-- สภาพ -->
            <CRow class="mb-3 align-items-center">
              <CCol col="3" class="text-right">
                <label>{{ $t('Condition') }}</label>
              </CCol>
              <CCol col="4">
                <select disabled v-model="condition" class="custom-select text-dark">
                  <option value="NEW" selected>{{ $t('new') }}</option>
                  <option value="USED">{{ $t('used') }}</option>
                </select>
              </CCol>
            </CRow>

            <!-- Parent SKU -->
            <CRow class="mb-3 align-items-center">
              <CCol col="3" class="text-right">
                <label>Parent SKU</label>
              </CCol>
              <CCol col="4">
                <CInput v-model="parentSKU" placeholder="-" />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <!-- <div style="">
          <br />
          <CRow class="justify-content-end">
            <CCol lg="2" col="6">
              <CButton style="
                  background-color: white;
                  margin-right: 15px;
                  box-shadow: 0 0 0 1px #e5e5e5;
                " block @click="cancel()" square
                  id="cancel-button">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
            <CCol lg="2" col="6">
              <CButton id="save-button" color="success" class="btn-block" style="" @click="validatePopup()" square>
                {{ $t('save') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </div> -->
      </form>
    </CCol>
    <CRow>
      <CModal :closeOnBackdrop="false" color="success" :show.sync="selectcolor" :title="$t('selectImg')" centered id="color-selection-modal">
        <div v-if="loadingSaveimg" id="loading-save-img">
          <CRow>
            <CCol md="1" sm="1" col="2" style="margin: 3px" v-for="item in colorList" :key="item.value"
              :value="item.value">
              <CButton v-if="indexColor === item.value" style="box-shadow: 0 0 5px 5px rgb(163, 222, 194); color: white"
                :class="{ active: indexColor === item.value }" @click="setindexcolor(item.value)"
                :style="{ backgroundColor: item.color }"
                :id="'color-option-button-' + item.value">
                <i class="fi fi-rr-check"></i>
              </CButton>
              <CButton v-else :style="{ backgroundColor: item.color }" :class="{ active: indexColor === item.value }" :id="'color-option-button-' + item.value"
                @click="setindexcolor(item.value)">
                <CIcon>
                  <i class="fas fa-square fa-2x"></i>
                </CIcon>
              </CButton>
            </CCol>
          </CRow>
          <hr />
          <div class="row">
            <div class="col-md-6 col-6">
              <CButton class="text-left" id="select-image-from-pc-button" ick="pickFile">
                <label id="image-file-input-label">{{ $t('selectImgFromPC') }}</label>
                <input id="image-file-input" v-show="imageDataBtn.length == 0" type="file" ref="image" style="" accept="image/*"
                  @change="selectedImage" />
              </CButton>
            </div>
            <div v-if="imageDataBtn.length > 0" class="col-md-6 col-6 text-right text-danger">
              <CButton @click="deleteImg" id="delete-image-button"> <i class="fi fi-rr-trash"></i>{{ $t('deleteImg') }}</CButton>
            </div>
            <div v-if="imageDataBtn.length > 0" class="col-md-12 text-center">
              <img v-if="isUpload" :src="imageData" alt="..." width="40%" @click="selectcolormodal" class="avatar" id="image-preview"/>
              <VueCropper v-else v-show="imageDataBtn" :guides="true" :aspectRatio="1.3" :initialAspectRatio="1.3"
                ref="cropper" :src="imageDataBtn" alt="Source Image" :zoomable="false" :scalable="false" id="image-cropper">
              </VueCropper>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-center" width="100%">
            <button class="btn" type="button" disabled style="color: darkcyan" id="uploading-spinner-button">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" id="uploading-spinner-icon">
              </span>
              {{ $t('กำลังอัปโหลดรูปภาพ...') }}
            </button>
          </div>
        </div>

        <template #footer-wrapper>
          <CRow class="p-4">
            <CCol>
              <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="savecolorimg" id="save-button">
                {{ $t('save') }}
              </CButton>
              <CButton block color="success" v-else-if="loadingSaveimg === false" disabled id="saving-spinner-button">
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol>
              <CButton block color="light" @click="selectcolor = false" id="cancel-button">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
    <CRow>
      <modal id="confirmation-modal" color="success" :show.sync="confirmModal" :title="$t('confirmSaveImg')" v-on:isSave="savedata" centered
        :alertError="alertError" :loading="loading">
        <div v-if="alertError">
          <h4 class="text-center text-danger" id="error-text">{{ textError }}</h4>
          <br />
          <h5 class="text-center text-danger" id="error-message">{{ textMessage }}</h5>
        </div>
        <div v-else>
          <br />
          <h4 class="text-center" id="confirmation-dialog-text">{{ $t('confirmSaveImgDialog') }}</h4>
          <br />
          <div v-if="loading == false">
            <CProgress class="mb-3" id="upload-progress-bar">
              <CProgressBar id="progress-bar" color="success" variant="striped" animated :value="uploadProgress" />
            </CProgress>
            <p class="text-center" id="upload-status-text">{{ onUploadText }}</p>
          </div>

        </div>
      </modal>
    </CRow>
    <CModal id="validation-modal" color="warning" :show.sync="validationModal" :title="$t('dialogfound')" centered>
      <CRow>
        <CCol class="text-center mt-2" style="font-size: 24px;" id="validation-messages-container">
          <p v-if="validateSKUName" class="text-warning" id="sku-name-warning">{{ validateSKUName }}</p>
          <p v-if="validateSKUPrice" class="text-warning" id="sku-price-warning">{{ validateSKUPrice }}</p>
          <p v-if="validateCategory" class="text-warning" id="category-warning">{{ validateCategory }}</p>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <div class="text-center mb-3">
          <CButton id="validation-submit-button" color="warning" @click="validationModal = false" class="text-white" style="width: 30%">{{
            $t('submit') }}</CButton>
        </div>
      </template>
    </CModal>
    <Logout
      :isVisible="loginAgainModal"
      @update:isVisible="loginAgainModal = $event"
    />
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import Logout from '../../../containers/Logout.vue'
import { mapGetters } from 'vuex'
import local from '@/services/local'
import util from '@/util/util'
import Multiselect from 'vue-multiselect'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import permis from '@/util/permission'
import uploadImage from '@/util/uploadImage'
import mime from '@/util/mime'
import router from '../../../router'
import { LazyYoutube } from "vue-lazytube";
import firebase from 'firebase'

export default {
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    actionPage: {
      type: String, // Define the type of `actionPage` (e.g., String, Number)
      required: false, // Set to `true` if required
      default: "", // Optional default value
    },
    localSkuType: {
      type: String,
      required: true,
    },
  },
  components: {
    Logout,
    Multiselect,
    VueCropper,
    LazyYoutube,
  },
  watch: {
    // Watch for changes to the `trigger` prop
    trigger(newValue) {      
      if (newValue) {
        this.validatePopup();
      }
    },
    localSkuType(newVal) {
      this.skuType = newVal; // Watch for parent changes
    },
  },
  data() {
    const isEcommercePage = this.$route.path === '/ecommerce/AddProductEcommerce';
    return {
      weight: 0,
      length: 0,
      height: 0,
      width: 0,
      shippingFree: false,
      logisticInfo: false,
      readyToShip: 'no',
      condition: 'NEW',
      parentSKU: '',
      pluStock: 0,
      productDescription: '',
      isEcommercePage: isEcommercePage,
      loginAgainModal : false,
      rows: [],
      price: 0,
      serviceCharge: false,
      selectcolor: false,
      colori: util.generateColor(1),
      colorindex: 1,
      vatType: 'V',
      favorite: true,
      isOnScreen: true,
      amount: '1',
      SKUPrice: 0,
      SKUName: '',
      productcode: '',
      stdCost: 0,
      previewImage: null,
      imageData: '',
      imageData2: '',
      imagePath: '',
      imagePath2: '',
      imageName: '',
      skuType: this.localSkuType, // Initialize local state
      isSuccess: false,
      dataUnit: [],
      category: null,
      unit: null,
      dataCategory: [],
      confirmModal: false,
      loadingButton: false,
      validateSKUName: '',
      validateSKUPrice: '',
      validateproductcode: '',
      validateShop: '',
      validateCategory: '',
      itemObjectId: '',
      imageDataBtn: '',
      coloribtn: '',
      image: {},
      remoteImagePath: '',
      additionImagePath: new Array(this.additionImageAmount),
      shop: this.$store.getters.shopObjectId,
      objectSKUId: '',
      indexColor: 1,
      negotiatePrice: false,
      popupModal: false,
      textError: '',
      textMessage: '',
      alertError: false,
      isError: 0,
      loading: true,
      loadingSaveimg: true,
      isUpload: true,
      isload: false,
      isMenu: false,
      isChannels: false,
      objectId: '',
      additionImageAmount: parseInt(process.env.VUE_APP_ADDITIONAL_IMAGE),
      localAdditionImagePath: new Array(this.additionImageAmount),
      additionalModal: false,
      currentImg: '',
      directoryPath: '',
      resizedImage: null,
      currentButton: 0,
      uploadVideoModal: false,
      videoOption: '',
      currentVideo: '',
      currentVideoHolder: '',
      maxFileSize: 20971520, //byte
      uploadProgress: 0,
      onUploadText: '',
      trackingOldVideoPath: '',
      validationModal: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isSelfOrderEnabled() {
      return permis.findPermissionRead('selfOrder', this.$route.path)
    },
    isEditData() {
      let path = '/product'
      const value = permis.findPermissionEdit('product', path)
      return value && this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.editableItem || false
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    colorList() {
      let data = []
      for (let i = 0; i < 30; i++) {
        data.push({
          color: util.generateColor(i),
          value: i,
        })
      }
      return data
    },
    categorylist() {
      let data = this.dataCategory
      let detail = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          })
        }
      }
      return detail
    },
    unitlist() {
      let data = this.dataUnit
      let detail = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          })
        }
      }
      return detail.sort(util.compare)
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled) {
          return true
        } else {
          return false
        }
      }
    },
    isSelfOrderEnabled() {
      const shop = this.shop || {}
      const delivery = shop.delivery || {}
      const enabled = delivery.isSelfOrderEnabled || false
      return enabled
    },
    showView() {
      return this.$route.query.view || 'grid'
    },
    sortdata() {
      return this.$route.query.sort || 'SKUName'
    },
    page() {
      return this.$route.query.page || 1
    },
    searchBy() {
      return this.$route.query.searchBy || ''
    },
    keyword() {
      return this.$route.query.keyword || ''
    },
    queryURL() {
      return (
        '?sort=' +
        this.sortdata +
        '&page=' +
        this.page +
        '&view=' +
        this.showView +
        '&searchBy=' +
        this.searchBy +
        '&keyword=' +
        this.keyword
      )
    },
  },
  created() {
    this.shop = this.shops.find((i) => i.objectId === this.shop)
    this.addRow()
    this.getUnit()
    this.getCategory()
    this.objectId = util.generateObjectId()
  },
  mounted() {
    document.addEventListener('keydown', this.doSave)
  },
  
  methods: {
    emitSkuType() {
      this.$emit("updateSkuType", this.skuType); // Emit changes to parent
    },
    validateDescription() {
      if (this.productDescription.length < 60) {
        return true
      }else{
        return false
      }
    },
    logout() {
      this.loadingButton = false
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          localStorage.clear()
          window.location.href = '/login'
        })
        .catch((error) => {
          console.log(error)
        })
    },
    selectVideo() {
      this.uploadVideoModal = true
    },
    saveVideo() {
      this.currentVideo = this.currentVideoHolder
      this.uploadVideoModal = false
    },
    delteVideo() {
      this.videoOption = ''
      this.currentVideo = ''
      this.currentVideoHolder = ''
    },
    handleVideoFileChange: function (event) {
      this.currentVideoHolder = '';
      let file = event.files[0];

      if (file.size <= this.maxFileSize) {
        this.$nextTick(() => {
          this.currentVideoHolder = window.URL.createObjectURL(file)
        })
      } else {
        event.target = '';
        alert(this.$i18n.t('bigVideoAlert'))
      }
    },
    goback() {
      this.$router.push('/product' + this.queryURL)
    },
    async cancel() {   
      if (this.remoteImagePath !== '') {
        await uploadImage.deleteImage(
          this.uid,
          this.shopObjectId,
          this.remoteImagePath
        )
      }
      router.push('/product')
    },
    doSave(e) {
      if (this.$router.app._route.name == 'newAddproduct') {
        if (e.key === 'Enter' && e.target.nodeName === 'INPUT') {
          // button:not([disabled]),
          var focusableElementsString =
            'a[href], area[href], input:not([disabled]),select ,select:not([disabled]), textarea:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
          let arr = Array.from(
            e.target.form.querySelectorAll(focusableElementsString)
          )

          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === e.target) {
              let indexOf = i < arr.length - 1 ? arr[i + 1] : indexOf[0]
              indexOf.focus()
              break
            }
          }
          e.preventDefault()
        }
        if (!(e.keyCode === 83 && e.ctrlKey)) {
          return
        } else if (e.keyCode === 83 && e.ctrlKey) {
          this.validatePopup()
        }
        e.preventDefault()
      }
    },
    validatePopup() {
      
      let validationPassed = true;

      // Validate SKU Name
      if (this.SKUName === '') {
        this.validateSKUName = this.$i18n.t('enterProduct');
        validationPassed = false;
      } else {
        this.validateSKUName = '';
      }

      // Validate SKU Price (Check for empty and valid number)
      if (this.SKUPrice === '' || isNaN(this.SKUPrice) || this.SKUPrice < 0) {
        this.validateSKUPrice = this.$i18n.t('priceMustBeGreaterThanZero');
        validationPassed = false;
      } else {
        this.validateSKUPrice = '';
      }

      // Validate Category
      if (this.category == null) {
        this.validateCategory = this.$i18n.t('selectCategory');
        validationPassed = false;
      } else {
        this.validateCategory = '';
      }

      // Show validation modal if any validation fails
      if (!validationPassed) {
        this.validationModal = true;
      } else {
        // Proceed with further logic if validation passes
        this.alertError = false;
        this.loading = true;
        this.confirmModal = true;
      }
    }
    ,
    setindexcolor(index) {
      this.indexColor = index
      this.colori = util.generateColor(index)
    },
    addRow() {
      this.rows.push({})
    },
    removeRow(row) {
      this.rows.splice(row, 1)
    },
    selectcolormodal() {
      this.selectcolor = true
    },
    getCurrentImage() {
      return this.currentImg
    },
    selectAdditionalImg(index) {
      this.currentButton = index
      if (this.localAdditionImagePath[index] !== undefined) {
        this.currentImg = this.localAdditionImagePath[index]
      } else {
        this.currentImg = ''
      }

      this.additionalModal = true
    },
    pickFile() {
      this.$refs.image.click()
    },
    savecolorimg() {
      this.loadingSaveimg = false
      if (this.isload) {
        let cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.imageData = cropedImage
        this.getImageURL()
      } else {
        this.selectcolor = false
        this.loadingSaveimg = true
      }
    },
    deleteLocalImg() {
      this.localAdditionImagePath[this.currentButton] = null
      this.$refs.cropper2.destroy()
      this.currentImg = ''
    },
    async saveAdditionalImg() {
      // this.loadingSaveimg = false
      let cropedImage = this.$refs.cropper2.getCroppedCanvas().toDataURL()
      const resizedImage = await this.resizeImage(cropedImage)

      this.localAdditionImagePath[this.currentButton] = resizedImage

      this.$refs.cropper2.destroy()
      this.currentImg = ''
      this.additionalModal = false
      // this.getImageURL2()
    },
    async getImageURL2() {
      for (let i = 0; i <= this.additionImageAmount; i++) {
        let ext = mime.getExtension(this.localAdditionImagePath[i]);
        if (ext == null) {
          this.isload = false;
        } else {
          const imageName = util.generateFilename('.' + ext);
          const ownerUID = `${localStorage.getItem('uid')}`;
          let ref = this.objectId;
          const directoryPath = 'resources/' + ownerUID + '/resources/products/' + ref + '/' + imageName;
          this.additionImagePath.push(await uploadImage.onUpload2(directoryPath, this.localAdditionImagePath[i]))
          if(!this.additionImagePath.startsWith('https://firebasestorage')){
          this.loginAgainModal = true
        } else {
          this.loginAgainModal = false
        }
        }
      }
    },


    async getImageURL() {
      let ref = this.objectId
      const getCroppedCanvas = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.resizedImage = await this.resizeImage(getCroppedCanvas)

      let ext = mime.getExtension(this.resizedImage)
      if (ext == null) {
        alert('Only Support File Type Is JPEG, PNG')
        this.isload = false
      } else {
        const oldImagePath = this.imagePath
        await uploadImage.deleteImage2(oldImagePath)

        const imageName = util.generateFilename('.' + ext)
        const ownerUID = `${localStorage.getItem('uid')}`
        this.directoryPath = 'resources/' + ownerUID + '/resources/products/' + ref + '/' + imageName
        this.selectcolor = false
        this.loadingSaveimg = true
        this.isload = false
      }
    },

    handleFileChange: function (event) {
      this.isUpload = true
      this.currentImg = '';
      let file = event.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        this.currentImg = e.target.result;
        this.$refs.cropper2 = this.currentImg
        this.isUpload = false
        this.$refs.fileInput.value = '';
      };
    },

    selectedImage(e) {
      this.imageDataBtn = ''

      const file = e.target.files[0]
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imageDataBtn = event.target.result
          this.$refs.cropper = this.imageDataBtn
          this.isUpload = false
          this.isload = true
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async resizeImage(base64Str, maxWidth = 650, maxHeight = 500) {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = maxWidth
          const MAX_HEIGHT = maxHeight
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
        }
      })
    },
    getUnit() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        page: 1,
        limit: 1000,
      }
      const shopObjectId = this.shopObjectId
      const headers = {
          shopObjectId: shopObjectId,
      }

      local
        .get('/api/v1.0/uid/' + uid + '/Unit/data', { params ,headers})
        .then((res) => {
          this.dataUnit = res.data.data
          let data = this.dataUnit
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              if (data[i].name == 'ชิ้น') {
                this.unit = {
                  id: data[i].id,
                  name: data[i].name,
                  objectId: data[i].objectId,
                }
              }
            }
          }
        })
    },
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        page: 1,
        limit: 1000,
      }
      const headers = {
          shopObjectId: shopObjectId,
      }

      local.get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
        params,headers
      }).then((res) => {
        this.dataCategory = res.data.data
        let data = this.dataCategory

        for (let i = 0; i < data.length; i++) {
          if (data[i].enabled === true) {

            // set default to uncategory
            if (data[i].id == '0') {
              this.category = {
                id: data[i].id,
                name: data[i].name,
                objectId: data[i].objectId,
              }
            }
          }
        }
      })
    },
    generateObjectId() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      local({
        method: 'get',
        headers: headers,
        url: '/api/v1.0/' + uid + '/objectId/generate',
      })
        .then((res) => {
          this.objectId = res.data.data.objectId
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async blobToDataURL(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async uploadVideoToFirebase() {
      const ownerUID = `${localStorage.getItem('uid')}`;
      let ref = this.objectId;

      if (this.videoOption == 'Upload') {
        this.onUploadText = this.$i18n.t('videoUploading')
        this.uploadProgress = 30
        let videoDataUrl = await this.blobToDataURL(this.currentVideoHolder)
        let upload = await uploadImage.onUpload2('resources/' + ownerUID + '/resources/products/' + ref + '/video001', videoDataUrl)
        this.currentVideo = upload
        if(!this.currentVideo.startsWith("https://firebasestorage")){
          this.loginAgainModal = true
        } else {
          this.loginAgainModal = false
        }
      } else {
        const uid = `${localStorage.getItem('shopsUid')}`
        const shopObjectId = this.shopObjectId
        await uploadImage.deleteImage(uid, shopObjectId, this.trackingOldVideoPath)
      }
    },

    async savedata() {
      this.loading = false
      await this.uploadVideoToFirebase()

      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      this.remoteImagePath = await uploadImage.onUpload2(this.directoryPath, this.resizedImage)

      this.additionImagePath.shift()
      await this.getImageURL2()

      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      if (this.productcode == '') {
        this.productcode = util.generateBarcode()
      }

      // Current shop
      const shop = {
        'objectId': this.shop.objectId,
        'id': this.shop.id,
        'branchName': this.shop.branchName,
      }

      let data = {
        'SKUName': this.SKUName,
        'SKUPrice': this.SKUPrice,
        'category': {
          id: this.category.id,
          name: this.category.name,
          objectId: this.category.objectId,
        },
        'categoryId': this.category.id,
        'enabled': true,
        'favorite': this.favorite,
        'indexColor': this.indexColor,
        'isOnScreen': this.isOnScreen,
        'productPLU': {
          PLUCode: this.productcode,
        },
        'serviceCharge': this.serviceCharge,
        'shop': shop,
        'shopId': this.shop.id,
        'skuType': this.skuType,
        'stdCost': this.stdCost,
        'unit': {
          id: this.unit.id,
          name: this.unit.name,
          objectId: this.unit.objectId,
        },
        'unitId': this.unit.id,
        'vatType': this.vatType,
        'negotiatePrice': this.negotiatePrice,
        'additionImagePath': this.additionImagePath,
        'remoteVideoPath': this.currentVideo,
        'description' : this.productDescription,
        
      }

      if (this.remoteImagePath.match(/http/gi)) {
        data.remoteImagePath = this.remoteImagePath
        data.imageName = ''
      }

      // For edit item.
      if (this.objectId != '') {
        data['objectId'] = this.objectId
      }

      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu })
      }

      const headers = {
        'shopObjectId': this.shopObjectId,
      }

      // save to api
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productsku/add',
        params: {
          'shopObjectId': shopObjectId,
          'plan': plan
        },
        data: data,
        headers: headers,
      }).then((res) => {        
        if (res.data.error.code === 4000) {
          this.textError = this.$i18n.t('failedToSave')
          this.textMessage = res.data.error.message
          this.alertError = true
        } else {
          this.alertError = false
          this.confirmModal = false
          this.isSuccess = true
          this.loading = true
          let objectId = res.data.data.objectId
          this.loginAgainModal = false
          this.$store.dispatch('setProductID', objectId)
          this.$store.dispatch('getProductSKUDatail')
          
          if(this.actionPage === ''){
            this.$router.push({
            name: "ProductDetail",
            params: { productSKUObjectId: objectId },
            query: {
                sort: this.$route.query.sort|| 'SKUName',
                page: this.$route.query.page || 1,
                view: this.$route.query.view || "grid",
                searchBy:this.$route.query.searchBy || "",
                keyword: this.$route.query.keyword || ""
            },
          });
            // this.$router.push("/product/productdetail/" +objectId)
          }else{
            this.$router.push({
            name: this.actionPage,
            params: { productSKUObjectId: objectId },
            query: {
                sort: this.$route.query.sort|| 'SKUName',
                page: this.$route.query.page || 1,
                view: this.$route.query.view || "grid",
                searchBy:this.$route.query.searchBy || "",
                keyword: this.$route.query.keyword || ""
              }
          });
            // this.$router.push("/product/productdetail/" + this.actionPage +'/' +objectId)
          }
         
        }
      })
        .catch((error) => {
          console.log(error)
        })
    },
    addProductgetID() {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      let data = []
      if (this.objectId != '') {
        data = {
          objectId: this.objectId,
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          category: {
            id: this.category.id,
            name: this.category.name,
            objectId: this.category.objectId,
          },
          categoryId: this.category.id,
          enabled: true,
          favorite: this.favorite,
          favoriteIndex: 99999,
          indexColor: this.indexColor,
          isOnScreen: this.isOnScreen,
          itemSequence: 10,
          productPLU: {
            PLUCode: this.productcode,
          },
          serviceCharge: this.serviceCharge,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          skuType: this.skuType,
          stdCost: this.stdCost,
          unit: {
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          remoteImagePath: this.remoteImagePath,
          vatType: this.vatType,
          negotiatePrice: this.negotiatePrice,
          description : this.productDescription,

        }
      } else {
        data = {
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          category: {
            id: this.category.id,
            name: this.category.name,
            objectId: this.category.objectId,
          },
          categoryId: this.category.id,
          enabled: true,
          favorite: this.favorite,
          favoriteIndex: 99999,
          indexColor: this.indexColor,
          isOnScreen: this.isOnScreen,
          itemSequence: 10,
          productPLU: {
            PLUCode: this.productcode,
          },
          serviceCharge: this.serviceCharge,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          skuType: this.skuType,
          stdCost: this.stdCost,
          unit: {
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          vatType: this.vatType,
          negotiatePrice: this.negotiatePrice,
          description : this.productDescription,

        }
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu })
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }

      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productsku/add',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then((response) => {
          setTimeout(() => {
            if (response.data.error.code === 4000) {
              this.textError = this.$i18n.t('failedToSave')
              this.textMessage = response.data.error.message
              this.isError = 10
            } else {
              this.loginAgainModal = false
              this.objectSKUId = response.data.data.objectId
              this.$store.dispatch('setProductID', this.objectSKUId)
              this.$store.dispatch('getProductSKUDatail')
              if(this.actionPage === ''){
                this.$router.push({
                name: "ProductDetail",
                params: { productSKUObjectId: this.objectSKUId },
                query: {
                    sort: this.$route.query.sort|| 'SKUName',
                    page: this.$route.query.page || 1,
                    view: this.$route.query.view || "grid",
                    searchBy:this.$route.query.searchBy || "",
                    keyword: this.$route.query.keyword || ""
                },
              });
                // this.$router.push("/product/productdetail/" +objectId)
              }else{
                this.$router.push({
                name: this.actionPage,
                params: { productSKUObjectId: this.objectSKUId},
                query: {
                    sort: this.$route.query.sort|| 'SKUName',
                    page: this.$route.query.page || 1,
                    view: this.$route.query.view || "grid",
                    searchBy:this.$route.query.searchBy || "",
                    keyword: this.$route.query.keyword || ""
                  }
              });
                // this.$router.push("/product/productdetail/" + this.actionPage +'/' +objectId)
          }
            }
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    validateadd() {
      if (this.SKUName == '') {
        this.validateSKUName = this.$i18n.t('enterProduct')
      } else {
        this.validateSKUName = ''
      }
      if (this.SKUPrice < 0) {
        this.validateSKUPrice = this.$i18n.t('enterPrice')
      } else {
        this.validateSKUPrice = ''
      }
      if (this.productcode == '') {
        this.productcode = util.generateBarcode()
      }

      if (
        this.SKUName !== '' &&
        this.SKUPrice !== '' &&
        this.productcode !== '' &&
        this.unit != null &&
        this.category != null
      ) {
        var con = confirm(this.$i18n.t('confirmSaveProduct'))
        if (con == true) {
          this.addProductgetID()
        }
      }
    },
    addChannels() {
      this.isChannels = true
      if (this.objectSKUId == '') {
        this.validateadd()
      } else {
        let productSKUObjectId = this.objectSKUId
        this.$router.push({
          name: 'ProductChannels',
          params: { productSKUObjectId },
        })
      }
    },
    addProductBOM() {
      this.isChannels = false
      if (this.objectSKUId == '') {
        this.validateadd()
      } else {
        let productSKUObjectId = this.objectSKUId
        this.$router.push({ name: 'BOMType', params: { productSKUObjectId } })
      }
    },
    addProductSerial() {
      this.isChannels = false
      if (this.objectSKUId == '') {
        this.validateadd()
      } else {
        let productSKUObjectId = this.objectSKUId
        this.$router.push({
          name: 'SerialType',
          params: { productSKUObjectId },
        })
      }
    },
    addProductPLU() {
      this.isChannels = false
      if (this.objectSKUId == '') {
        this.validateadd()
      } else {
        let productSKUObjectId = this.objectSKUId
        this.$router.push({ name: 'PLUType', params: { productSKUObjectId } })
      }
    },
    async deleteImg() {
      const oldImagePath = this.imagePath
      await uploadImage.deleteImage2(oldImagePath)

      this.directoryPath = ''
      this.resizedImage = null
      this.remoteImagePath = ''
      this.imageData = ''
      this.imageDataBtn = ''
      this.remoteImagePath = ''
      this.imagePath = ''
      this.$refs.image.value = null

    },
    validator(val) {
      return val ? val.length >= 1 : false
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.vali {
  position: absolute;
  margin: -7px;
  color: red;
}

.btn-add {
  border-color: #29b289;
  text-align: center;
  width: -webkit-fill-available;
  color: #29b289;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #29b289;
  background-color: #29b289;
}

.form-control {
  cursor: pointer;
}

.custom-select {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.multiselect__tags {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}

.multiselect__single {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  font-size: 14px;
  cursor: pointer;
}

/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media screen and (min-width: 568px) {
  .imagelabel {
    text-align: right;
  }
}
</style>

<style src="../../../assets/styles/imgProduct.css"></style>
